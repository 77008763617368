import { ADICIONAR_ITEM, REMOVER_ITEM, REMOVER_ITENS } from '../actions/index';
import _ from 'lodash';

export default function (state = [], action) {
    switch (action.type) {
        case ADICIONAR_ITEM:
            return [action.payload, ...state]  
            //return state.concat([action.payload]);
        case REMOVER_ITEM:
            return state.filter(item => !_.isEqual(item, action.payload));
        case REMOVER_ITENS:
            return [];
        default:
            return state;
    }   
}
