import React, { Component } from 'react';
import { connect } from 'react-redux';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import green from '@material-ui/core/colors/green';
import MoreVert from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListeItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pt-br';
import { obterEmpresas, removerCompromisso } from '../actions/index' 
import axios from 'axios';
import toastr from 'toastr';
import { mensagemErro } from '../utils/api_util';
import APIUtils from '../utils/api_util';



class ListaCompromissos extends Component {

  constructor(props){
    super(props);
    this.state = { showConfirmacao: false, obs: '', compromisso: {} };
    this.renderCompromisso = this.renderCompromisso.bind(this);
    this.onObsChange = this.onObsChange.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.registrarVisita = this.registrarVisita.bind(this);
    this.salvando = false;
  }

  componentDidMount() {
    if(_.isEmpty(this.props.empresas)){            
        this.props.obterEmpresas();
    }
}

  handleCloseConfirm() {
    this.setState({ showConfirmacao: false, obs: '', compromisso: {} });
  }

  onObsChange(e){
    this.setState( { obs: e.target.value } );
  }

  async registrarVisita(){
    if(this.salvando){
      toastr.warning("Visita já está sendo salva!");
      return;
    }        
    this.salvando = true;
    const dados = {
      idRep: this.props.parametros.idRep,
      obs: this.state.obs,
      idComp: this.state.compromisso.id,
      guid: new Date().getTime(),
    }    
    if(this.state.compromisso.idCli > 0) {
      // Visita para cliente
      dados["idCli"] = this.state.compromisso.idCli;
      dados['tipo'] = 0;
    }else{
      // Visita para prospect
      dados["idProsp"] = this.state.compromisso.idProsp;
      dados['tipo'] = 1;
    }

    try {                  
      const url = `${APIUtils.API_URL}/api/v1/visitas`;            
      const config = { headers: { 'Content-type': 'application/json' }, timeout: 8000};                
      await axios.post(url, JSON.stringify(dados), config);
      toastr.info("Visita salva com sucesso!");      
      this.props.removerCompromisso(this.state.compromisso.id);
      this.setState({ showConfirmacao: false, compromisso: {} });      
    } catch(e){  
        toastr.error("Erro salvando visita: " + mensagemErro(e));
    } finally{
        this.salvando = false;
    }  
  }

  renderCompromisso(compromisso) {    
    const dataHora = moment(compromisso.data).format('DD/MM/YYYY') + (compromisso.hora ? ' - ' + compromisso.hora : '');
    const empresa = _.find(this.props.empresas, (e) => {
      if(compromisso.idCli > 0){
        // Cliente
        return (e.tipo === 0 && e.idEmp === compromisso.idCli);
      }else{
        // Prospect
        return (e.tipo === 1 && e.idEmp === compromisso.idProsp);
      }
    }).nome;
  

    let corAvatar = red[300];
    let nomenclatura = 'A';
    let textoNomenclatura = 'Assistente/interno';
    if (compromisso.tipo === 1) {
      corAvatar = yellow[500];
      nomenclatura = 'I';
      textoNomenclatura = 'Instalador';
    }
    if (compromisso.tipo === 2) {
      corAvatar = green[500];
      nomenclatura = 'R';
      textoNomenclatura = '';

    }
    const opcRegistrar = (
    <div>
      <IconButton onClick={() => this.setState({showConfirmacao: true, compromisso})} color="inherit">
        <MoreVert/>
      </IconButton>  
    </div>
    );
    const rightIconMenu = compromisso.tipo === 0 ? null : opcRegistrar;
    return (          
      <div key={compromisso.id}>
        <ListItem>
          <ListItemAvatar>
            <Avatar style={{backgroundColor: corAvatar}}>{nomenclatura}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={empresa} secondary={dataHora + '-' + textoNomenclatura + '-' + compromisso.descr}/>
          <ListeItemSecondaryAction>{rightIconMenu}</ListeItemSecondaryAction>
        </ListItem> 
        <Divider variant="inset" />
      </div>
    );
  }

  render() {
    if(_.isEmpty(this.props.compromissos) || _.isEmpty(this.props.empresas)){
      return <div style={{paddingTop: 50,}}>Carregando...</div>;
    }
    return (
      <div style={{paddingTop: 50,}}>
        <List>
          {this.props.compromissos.map(c => {
            return this.renderCompromisso(c)
          })}
        </List>
        <Dialog open={this.state.showConfirmacao}>
          <DialogTitle>{"Confirma essa visita ?"}</DialogTitle>
          <DialogContent>
            <TextField
              value={this.state.obs}  
              fullWidth={true}
              label="Digite uma observação"
              onChange={this.onObsChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseConfirm} color="primary">
              Cancelar
            </Button>
            <Button
                label="Confirmar"
                autoFocus={true}
                onClick={this.registrarVisita}
                disabled={this.state.obs === ''}>
              Confirmar
            </Button>
          </DialogActions>        
        </Dialog>        
        </div>
    );
  }

}

function mapStateToProps(state) {
  return {
    parametros: state.parametros,
    compromissos: state.compromissos,
    empresas: state.empresas
  };
}

export default connect(mapStateToProps, { obterEmpresas, removerCompromisso })(ListaCompromissos);    