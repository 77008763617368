import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import HistoricoVendaCli from './historico_venda_cli'
import ContasAberto from './contas_aberto_cli'
import FrameCliente from './frame_cliente'
import PedidosEmAberto from './pedidos_em_aberto'
import AnaliseABC from './analise_abc'
import _ from 'lodash'
import { selecionarTransp, selecionarCondicao } from '../actions/index'
import toastr from 'toastr'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

class PedidoInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      exibeHistoricoCliente: false,
      exibeAnaliseABC: false,
      obs: '',
    }
  }

  onHistoricoClienteClick() {
    this.setState({ exibeHistoricoCliente: !this.state.exibeHistoricoCliente })
  }

  onAnaliseABCClick = () => {
    this.setState({ exibeAnaliseABC: !this.state.exibeAnaliseABC })
  }

  setCliente(cliente) {
    if (!_.isEmpty(cliente)) {
      if (cliente.status === 2) {
        this.props.setCliente({})
        this.props.selecionarTransp({})
        this.props.selecionarCondicao({})

        toastr.error('Cliente bloqueado')
        return
      }

      this.props.setCliente(cliente)
      this.setState({
        obs: cliente.obsPed,
      })
      this.selecionarTransportadora(cliente.idTrp)
      this.selecionarCondicao(cliente.idCond)
    } else {
      this.props.setCliente({})
      this.props.selecionarTransp({})
      this.props.selecionarCondicao({})
    }
  }

  selecionarTransportadora(id) {
    if (id > 0) {
      const transp = this.props.transportadoras.find(
        (transp) => transp.id === id
      )
      this.props.selecionarTransp(transp)
    }
  }

  selecionarCondicao(id) {
    if (id > 0) {
      const condicao = this.props.condicoes.find((cond) => cond.id === id)
      this.props.selecionarCondicao(condicao)
    }
  }

  resetHistorico = () => {
    this.setState({ exibeHistoricoCliente: false, exibeAnaliseABC: false })
  }

  render() {
    const { classes } = this.props

    return (
      <ExpansionPanel defaultExpanded>
        <Dialog
          open={Boolean(this.state.obs)}
          onClose={() => this.setState({ obs: null })}
        >
          <DialogTitle
            style={{
              textAlign: 'right',
            }}
          >
            <IconButton
              onClick={() => {
                this.setState({ obs: null })
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.obs}</DialogContentText>
          </DialogContent>
        </Dialog>
        <ExpansionPanelSummary
          className={classes.expansionPanelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Informações</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <div className={classes.container}>
            <div
              style={{ flex: 1, minWidth: '240px', alignSelf: 'center' }}
              onClick={this.resetHistorico}
            >
              <FrameCliente
                selecionarCliente={this.setCliente.bind(this)}
                default={this.props.cliente}
              />
            </div>
          </div>
          <div style={{ padding: 10, paddingTop: 0 }}>
            <ul
              style={{
                cursor: 'pointer',
                margin: 0,
                padding: 0,
                display: 'flex',
              }}
            >
              <li
                onClick={this.onHistoricoClienteClick.bind(this)}
                className={classes.linkHistorico}
                style={{ marginRight: 15 }}
              >
                Histórico de vendas
              </li>
              <li
                onClick={this.onAnaliseABCClick}
                className={classes.linkHistorico}
              >
                Curva ABC
              </li>
            </ul>
          </div>

          {this.state.exibeHistoricoCliente && !_.isEmpty(this.props.cliente) && (
            <div>
              <HistoricoVendaCli idCli={this.props.cliente.id} />
              <ContasAberto idCli={this.props.cliente.id} />
              <PedidosEmAberto idCli={this.props.cliente.id} />
            </div>
          )}
          {this.state.exibeAnaliseABC && !_.isEmpty(this.props.cliente) && (
            <AnaliseABC idCli={this.props.cliente.id} />
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 10,
  },

  expansionPanelSummary: {
    paddingLeft: 20,
    background: '#ddd',

    minHeight: 35,
    height: 35,
  },

  expansionPanelDetails: {
    display: 'block',
    padding: 0,
  },

  linkHistorico: {
    textDecoration: 'underline',
    cursor: 'pointer',
    listStyleType: 'none',
    color: '#3f51b5',
  },
}

function mapStateToProps(state) {
  return {
    transportadoras: state.transportadoras,
    condicoes: state.condicoes,
  }
}

export default connect(mapStateToProps, {
  selecionarTransp,
  selecionarCondicao,
})(withStyles(styles)(PedidoInfo))
