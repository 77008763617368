import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import CustomTable from './custom_table'
import FormLabel from '@material-ui/core/FormLabel';
import Chip from './custom_chip';

const AnaliseABC = props => {

    const url = `${APIUtils.API_URL}/api/v1/historico/vendas/abc`

    const [analise, setAnalise] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchAnaliseABC();
    //eslint-disable-next-line        
    }, [])

    const fetchAnaliseABC = async () => {
        setLoading(true);

        const response = await axios.get(`${url}/${props.idCli}/${props.parametros.idRep}`);
        const analiseABC = response.data;

        const reduceValor = analiseABC.reduce((prev, cur) => {
            return prev + cur.valor
        }, 0)

        analiseABC.forEach(item => {
            item.partic = (item.valor / reduceValor * 100).toFixed(2); 
        });

        setLoading(false)
        setAnalise(analiseABC);
    }

    return (        
        <div style={{ padding: '10px 10px' }}>            
            <FormLabel style={{ marginBottom: 10 }} component="legend">Análise ABC de Vendas</FormLabel>                                                    
            { loading &&
                <p>Carregando...</p>
            }
            { !loading && analise.length > 0 &&
                <CustomTable header={header} dados={analise}/>          
            }            
            { !loading && analise.length === 0 &&
                <Chip label="Nenhum registro encontrado!" />          
            }            
        </div>
    )
}

const mapStateToProps = state => {
    return {
        parametros: state.parametros
    }
}

const header = 
    [{field: 'codAlt', label: 'Código', type: ''}, 
     {field: 'descr', label: 'Descrição', type: ''}, 
     {field: 'qtde', label: 'Qtde. total', type: 'numeric'},
     {field: 'valor', label: 'Valor', type: 'numeric'},
     {field: 'partic', label: 'Partic.', type: 'numeric'},
     {field: 'data', label: 'Ult. venda', type: ''},
     {field: 'qtdeUltVenda', label: 'Qtde.', type: 'numeric'},
     {field: 'valorUltVenda', label: 'Valor.', type: 'numeric'}]

export default connect(mapStateToProps, null)(AnaliseABC)