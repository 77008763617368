import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { formatarCnpjCpf } from '../utils/formatador_util';
import Description from '@material-ui/icons/Description';
import IconButton from '@material-ui/core/IconButton';
import FormCliente from './form_cliente';

const TableClientes = (props) => {
  const { classes } = props;

  const [idCliente, setIdCliente] = useState(null);

  function renderHeader() {
    return (
      <TableHead className={classes.thead}>
        <TableRow className={classes.tbodyTr}>
          <TableCell style={{ width: '40%' }} className={classes.tableCell}>
            Nome
          </TableCell>
          <TableCell className={classes.tableCell}>CPF/CNPJ</TableCell>
          <TableCell className={classes.tableCell}>IE/RG</TableCell>
          <TableCell className={classes.tableCell}>Cidade</TableCell>
          <TableCell className={classes.tableCell}>Telefone</TableCell>
          <TableCell className={classes.tableCell}>Cód</TableCell>
          <TableCell className={classes.tableCell}>Ação</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function renderBody() {
    if (!props.clientes) {
      return null;
    }
    return (
      <TableBody>
        {props.clientes
          .slice(props.page * 10, (props.page + 1) * 10)
          .map((cliente) => {
            return (
              <TableRow key={cliente.id} className={classes.tbodyTr}>
                <TableCell className={classes.tableCell}>
                  {cliente.nome}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {formatarCnpjCpf(cliente.cpfCnpj)}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {cliente.ieRg}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {cliente.cidade}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {cliente.fone}
                </TableCell>{' '}
                <TableCell className={classes.tableCell}>
                  {cliente.codAlt}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <IconButton
                    style={{ padding: 2 }}
                    onClick={() => {
                      setIdCliente(cliente.id);
                    }}
                    title="Visualizar cadastro..."
                  >
                    <Description fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table style={{ minWidth: 800 }}>
        {renderHeader()}
        {renderBody()}
        <TablePagination
          count={props.nroClientes}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          component="div"
          page={props.page}
          rowsPerPage={10}
          rowsPerPageOptions={[]}
          onChangePage={props.changePage}
          backIconButtonText="Página Anterior"
          nextIconButtonText="Próxima Página"
        />
      </Table>

      {idCliente && (
        <FormCliente
          openDialog={idCliente !== null}
          id={idCliente}
          handleClose={() => {
            setIdCliente(null);
          }}
        />
      )}
    </Paper>
  );
};

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },

  status: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '0.75em',
    fontWeight: 'bold',
    borderRadius: '10px',
    padding: 7,
  },

  thead: {
    background: '#ccc',
  },

  theadTr: {
    height: '30px',
  },

  tbodyTr: {
    height: '30px',
  },

  tableCell: {
    padding: '4px 4px 4px 4px',
    '&:last-of-type': { paddingRight: '4px' },
    '& button': { width: 20, height: 20 },
    '& button > span > svg': { fontSize: 18 },
  },
};

export default withStyles(styles)(TableClientes);
