import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import APIUtils from '../utils/api_util';

function PaperComponent(props) {
    return (
        <Paper style={{ margin: 5 }} {...props}/>
    );
}

const FotoProduto = props => {
    const [foto, setFoto] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [erro, setErro] = useState(false)


    
    useEffect(() => {
        const fetchFoto = async () => {
            const url = `${APIUtils.API_URL}/api/v1/produtos/foto` 
            try{
                const response = await axios.get(`${url}/${props.id}`, {
                    responseType: 'blob'
                });
                if(response.data && response.data.size > 0) {
                    setFoto(response.data)
                }
                setErro(false)
            }catch(e) {
                setErro(true)
            }
            setLoading(false)   
        }    
        if(props.id > 0) {
            setLoading(true)
            fetchFoto();
        }else{
            setFoto(undefined)
        }
        
    }, [props.id]);  

    const handleClose = () => {
        props.handleClose();
    }

    return (
        <div>
            <Dialog open={props.openDialog} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" onClick={handleClose}>
                <DialogContent style={{ padding: 10, display: 'flex', flexDirection: 'column' }}>
                {erro && <h2>Erro carregando foto</h2>}
                {!erro && !foto && !loading && <h2>Produto não possuí foto no cadastro</h2>}
                {!erro && !foto && loading && <h2>Carregando...</h2>}
                {!erro && foto && <img src={URL.createObjectURL(foto)} alt="foto produto" style={{width: '100%', height: 'auto'}}/>}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default FotoProduto