export function formatarCnpjCpf(valor) {
  let vlrFormatado;
  if (valor.length === 14) {
    vlrFormatado = `${valor.substr(0, 2)}.${valor.substr(2, 3)}.${valor.substr(
      5,
      3
    )}/${valor.substr(8, 4)}-${valor.substr(12)}`;
  } else {
    vlrFormatado = `${valor.substr(0, 3)}.${valor.substr(3, 3)}.${valor.substr(
      6,
      3
    )}-${valor.substr(9)}`;
  }
  return vlrFormatado;
}
