import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Refresh from '@material-ui/icons/Refresh';

class RefreshAction extends Component {
    render() {
        return(
            <div style={{ padding: '10px 10px' }}> 
                <div style={{ textAlign: 'center' }}>
                    <Button onClick={this.props.action} size="small" color="default">
                        <Refresh />
                        {this.props.descr}
                    </Button>   
                </div>
            </div>

        );        
    }
}

export default RefreshAction;