import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination'
import { withStyles  } from '@material-ui/core/styles'
import _ from 'lodash';


const TableHistoricoPedidos = (props) => {

    const { classes, statusOptions } = props

    const origemOptions = [
        "Direto","Web","E-mail","Fone","Fax","Orçamento","Palm","Balcão"
    ]

    function buscarOrigem(origem){
        return origemOptions.find((el, key) => key === origem)
    }

    function buscarStatus(status){
        return statusOptions.find((el, key) => key === status)
    }

    function renderHeader() {
        return (
            <TableHead  className={ classes.thead }>
                <TableRow className={ classes.tbodyTr }>
                    <TableCell className={ classes.tableCell }>Status</TableCell>
                    <TableCell className={ classes.tableCell }>Origem</TableCell>
                    <TableCell className={ classes.tableCell }>Data</TableCell>
                    <TableCell className={ classes.tableCell }>Número</TableCell>
                    <TableCell className={ classes.tableCell }>Cliente</TableCell>
                    <TableCell className={ classes.tableCell } align='right'>Valor (R$)</TableCell>
                </TableRow>    
            </TableHead>    
        )
    }

    function renderBody() {

        if(!props.historico){
            return null;
        }

        return (
            <TableBody>
                { props.historico.map(pedido => {
                    return (
                        <TableRow key={ pedido.id } className={ classes.tbodyTr }>
                            <TableCell className={ classes.tableCell }>
                                <div className={ classes.status } style={ corStatus(pedido.status) }>
                                    { buscarStatus(pedido.status) }
                                </div>
                            </TableCell>
                            <TableCell className={ classes.tableCell }>{ buscarOrigem(pedido.origem) }</TableCell>
                            <TableCell className={ classes.tableCell }>{ pedido.data }</TableCell>
                            <TableCell className={ classes.tableCell }>{ pedido.id }</TableCell>
                            <TableCell className={ classes.tableCell }>{ pedido.cliente }</TableCell>
                            <TableCell className={ classes.tableCell } align='right'>{ pedido.vlr.toLocaleString('pt-br', {minimumFractionDigits: 2}) }</TableCell>
                        </TableRow>    
                    )
                })}
            </TableBody>    
        )
    }

    function corStatus(status) {
        if (_.isEqual(status, "Em aberto") || _.isEqual(status, "Em separação") || _.isEqual(status, "Separado") || _.isEqual(status, "Em conferência") || _.isEqual(status, "Conferido")) {
            return { background: '#14d85d' }
        }
        if (_.isEqual(status, "Atendido parcial")) {
            return { background: '#eed800' }
        }
        if (_.isEqual(status, "Atendido")) {
            return { background: '#000' }
        }
        if (_.isEqual(status, "Bloqueado")) {
            return { background: '#d01b1b' }
        }
        if (_.isEqual(status, "Cancelado")) {
            return { background: '#817578' }
        }
        return { background: '#fff', color: '#000' } 
    }

    return (
        <Paper className={classes.root}>
            <Table>
                {renderHeader()}
                {renderBody()}
                <TablePagination
                    count={props.nroPedidos}
                    labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
                    component="div"
                    page={props.page}
                    rowsPerPage={20}
                    rowsPerPageOptions={[]}
                    onChangePage={props.changePage}
                    backIconButtonText="Página Anterior"
                    nextIconButtonText="Próxima Página"
                />
            </Table>
        </Paper>    
    )
}

const styles = ({

    root: {
        width: '100%',
        overflowX: 'auto'
    },

    status: {
        textAlign: 'center', 
        color: '#fff', 
        fontSize: '0.75em', 
        fontWeight: 'bold',
        borderRadius: '10px',
        padding: 7
    },

    thead: {
        background: '#ccc'
    },

    theadTr: {
        height: '30px'
    },

    tbodyTr: {
        height: '30px'
    },

    tableCell: {
        padding: '4px 4px 4px 4px',
        '&:last-of-type': { paddingRight: '4px' },
        '& button': { width: 20, height: 20 },
        '& button > span > svg': { fontSize: 18 }
    }            
})

export default withStyles(styles)(TableHistoricoPedidos)