import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Chip from './custom_chip';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';
import TableClientesAtraso from './table_clientes_atraso';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DateRange from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
class ClientesAtraso extends Component {
  constructor(props) {
    super(props);
    moment.locale('pt-BR');

    this.state = {
      clientes: undefined,
      erro: false,
      loading: false,
      dataIni: moment().add(-3, 'years'),
      nroClientes: 0,
      page: 0,
    };
    this.onDataIniChange = this.onDataIniChange.bind(this);
    this.obterClientes = this.obterClientes.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (_.isEmpty(this.props.parametros)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async obterClientes(page = 0) {
    this.setState({ loading: true });
    const idRep = this.props.parametros.idRep;
    if (idRep > 0) {
      const url = `${
        APIUtils.API_URL
      }/api/v1/cr/cliente/atraso/${idRep}/${this.state.dataIni.format(
        'DD-MM-YYYY'
      )}?page=${page}&size=20`;
      try {
        const response = await axios.get(url);

        if (this.mounted) {
          this.setState({
            clientes: response.data.content,
            nroClientes: response.data.totalElements,
            erro: false,
            loading: false,
          });
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({
            loading: false,
            clientes: null,
            nroClientes: 0,
            erro: true,
          });
        }
      }
    }
  }

  onDataIniChange(data) {
    this.setState({ dataIni: data });
  }

  onPageChange(e, newPage) {
    this.setState({ page: newPage });

    this.obterClientes(newPage);
  }

  render() {
    return (
      <div>
        <div style={{ padding: '10px 10px' }}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale="pt-br"
          >
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  autoOk
                  label="Vencidos a partir de"
                  value={this.state.dataIni}
                  onChange={(date) => this.onDataIniChange(date)}
                  style={{ width: '100%' }}
                  disableFuture={true}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="Selecione uma data"
                        color="inherit"
                      >
                        <DateRange />
                      </IconButton>
                    ),
                  }}
                  format="DD/MM/YYYY"
                ></DatePicker>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{ display: 'flex', alignItems: 'end' }}
              >
                <IconButton
                  onClick={() => {
                    this.setState({ page: 0 });
                    this.obterClientes();
                  }}
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ padding: '5px 5px' }}>
          <Link
            style={{
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
            }}
            to="/"
          >
            <NavigateBeforeIcon />
            <Typography variant="button">Voltar</Typography>
          </Link>
        </div>
        {this.state.clientes !== undefined && (
          <>
            {this.state.loading ? (
              <div>Carregando...</div>
            ) : (
              <>
                {_.isEmpty(this.state.clientes) ? (
                  <>
                    {this.state.erro ? (
                      <div>
                        <RefreshAction
                          descr="Histórico de Clientes em Atraso"
                          action={this.obterClientes.bind(this)}
                        />
                      </div>
                    ) : (
                      <>
                        {this.state.clientes === null ? (
                          <div>Nenhum dado encontrado...</div>
                        ) : (
                          <div>
                            <div>
                              <Chip label="Representante não possui nenhum cliente em atraso" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ padding: '10px 10px' }}>
                      <FormLabel
                        style={{
                          marginBottom: '10px',
                          fontSize: '0.8em',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        component="legend"
                      >
                        <div>Histórico de Clientes em Atraso</div>
                        <div>Total de Clientes: {this.state.nroClientes}</div>
                      </FormLabel>
                      <TableClientesAtraso
                        clientes={this.state.clientes}
                        nroClientes={this.state.nroClientes}
                        page={this.state.page}
                        changePage={this.onPageChange}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { parametros: state.parametros };
}

export default connect(mapStateToProps, null)(ClientesAtraso);
