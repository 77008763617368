import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import DropDownMenu from './dropdown_menu';
import ButtonDropDown from './button_dropdown';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/DeleteForever';

const TableItensPedido = props => {
    const { classes, itens } = props
    return (
        <div>
            <Table>
                <TableHead style={{  background: '#ccc' }}>
                    <TableRow style={{ height: 30 }}>
                        <TableCell className={classes.tableCell}>Produto</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Qtde</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Desc(%)</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Valor liq.</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Valor total</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Valor lista</TableCell>
                        <TableCell className={classes.tableCell} align='right'>Tot c/ IPI</TableCell>
                        <TableCell className={classes.tableCell}>Ações</TableCell>                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    { itens.map((item) => {
                        return (
                            <TableRow key={_.uniqueId()}>
                                <TableCell className={classes.tableCellDescr} key={_.uniqueId()}>{item.codAlt + '-' + item.descr}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{item.qtde}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{item.alqDesc}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{parseFloat(item.vlrUnitLiq).toFixed(2)}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{parseFloat(item.vlrTotLiq).toFixed(2)}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{parseFloat(item.vlrUnit).toFixed(2)}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()} align='right'>{parseFloat(item.vlrTotIPI).toFixed(2)}</TableCell>
                                <TableCell className={classes.tableCell} key={_.uniqueId()}>                                
                                    <DropDownMenu>
                                        <ButtonDropDown onClick={() => props.editar(item)} label="Editar"><Create/></ButtonDropDown>
                                        <ButtonDropDown onClick={() => props.excluir(item)} label="Excluir"><Delete/></ButtonDropDown>
                                    </DropDownMenu>
                                </TableCell>

                            </TableRow>
                        )
                    })} 
                </TableBody>
            </Table>
        </div>
    )
}

const styles = {
    tableCell: {
        padding: '4px 4px 4px 4px',
        '&:last-of-type': { paddingRight: 0 },
        '& button' : { padding: 7 },
        '& button > span > svg': { fontSize: 16 }
    },            
    tableCellDescr: {
        fontSize: 10,
        padding: '4px 4px 4px 4px',
        '&:last-of-type': { paddingRight: 0 },
        '& button' : { padding: 7 },
        '& button > span > svg': { fontSize: 16 }
    }            

}

export default withStyles(styles)(TableItensPedido)