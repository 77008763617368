import React, { Component } from 'react';
import PedidoInfo from './pedido_info'; 
import PedidoProd from './pedido_prod'; 
import PedidoTotal from './pedido_total'
import { connect } from 'react-redux';
import { selecionarCliente } from  '../actions/index';
import _ from 'lodash';

class Pedido extends Component {


    setCliente(cliente) {
        if(!_.isEqual(cliente, this.props.clienteSelecionado)){
            this.props.selecionarCliente(cliente);
        } 

    }

    render() {
        return (
            <form noValidate autoComplete="off">
                <PedidoInfo cliente={this.props.clienteSelecionado} setCliente={this.setCliente.bind(this)}/>
                <PedidoProd cliente={this.props.clienteSelecionado}/>
                <PedidoTotal cliente={this.props.clienteSelecionado} setCliente={this.setCliente.bind(this)}/>
            </form>
        );
    }    
}  

function mapStateToProps(state) {    
    return {
        clienteSelecionado: state.clienteSelecionado,
    };
}

export default connect(mapStateToProps, { selecionarCliente })(Pedido);