import * as toastr from 'toastr';
import { OBTER_CONDICOES } from '../actions/index';

export default function (state = {}, action) {
    switch (action.type) {
        case OBTER_CONDICOES:                
            if(action.error){
                toastr.error("Erro obtendo condições.");
                return state;                
            }else{           
                return action.payload;
            }
        default:
            return state;
    }   
}
