import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import _ from 'lodash';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import Chip from './custom_chip'
import TableContasAberto from './table_contas_aberto';

class ContasAbertoCli extends Component {

    constructor(props) {
        super(props);
        this.state = { contas: null, resumo: null, erro: false };
    }

    componentDidMount() {
        this.mounted = true;
        this.obterContas(this.props.idCli);
        this.obterResumoContas(this.props.idCli);
    }

    componentDidUpdate(prevProps) {      
        if(this.props.idCli !== prevProps.idCli){
            this.obterContas(this.props.idCli);
            this.obterResumoContas(this.props.idCli);
        }   
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    async obterContas(idCli){        
        if(idCli > 0){
            const url = `${APIUtils.API_URL}/api/v1/cr/aberto/${idCli}`;
            try{
                const response = await axios.get(url);
                if(this.mounted){
                    this.setState({ contas: response.data, erro: false});
                }
                
            } catch(e){
                if(this.mounted){
                    this.setState({ contas: null, erro: true});
                }
            }
        }      
    }   

    async obterResumoContas(idCli){        
        if(idCli > 0){
            const url = `${APIUtils.API_URL}/api/v1/cr/aberto/resumo/${idCli}`;
            try{
                const response = await axios.get(url);
                if(this.mounted){
                    this.setState({ resumo: response.data });
                }
                
            } catch(e){
                if(this.mounted){
                    this.setState({ resumo: null });
                }
            }
        }      
    }   


    render() {
        if(!_.isEmpty(this.state.contas)){
            return(
                <div style={{ padding: '10px 10px' }}>
                    <FormLabel style={{marginBottom: '10px'}} component="legend">Contas em aberto</FormLabel>                                                    
                    <TableContasAberto contas={this.state.contas}/>
                    {!_.isEmpty(this.state.resumo) &&
                    <div style={{margin: 5}}>
                        <b>Total a vencer (sem considerar hoje): {(this.state.resumo.totalVencer - this.state.resumo.totalVencendoHoje).toFixed(2).toLocaleString()}</b>
                        <br/>
                        <b>Total vencido (considerando hoje): {(this.state.resumo.totalVencido + this.state.resumo.totalVencendoHoje).toFixed(2).toLocaleString()}</b>
                        <br/>
                        <b>Total vencido c/ juros: {(this.state.resumo.totalVencidoJuros + this.state.resumo.totalVencendoHoje).toFixed(2).toLocaleString()}</b>
                    </div>
                    }
                </div> 
            )             
        }else{
            if(this.state.erro){
                return <RefreshAction descr='Contas em aberto' action={this.obterContas.bind(this, this.props.idCli)}/>            
            }else{
                if(this.props.idCli > 0){
                    if(this.state.contas === null){
                        return <div>Carregando...</div>    
                    }else{                        
                        return <div><Chip label="Nenhuma conta em aberto"/></div>                    
                    }
                }else{
                    return <div/>
                }
            }
        }        
    }
}

export default ContasAbertoCli;