import { SELECIONAR_CLIENTE } from '../actions/index';
import _ from 'lodash'

export default function (state = {}, action) {
    switch (action.type) {
        case SELECIONAR_CLIENTE:
            return _.isEmpty(action.payload) ? {} : action.payload;
        default:
            return state;
    }
}
