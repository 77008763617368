import React, { Component } from 'react';
import { connect } from 'react-redux';
import FramePesquisa from './frame_pesquisa';
import FotoProduto from './foto_produto';

class FrameProduto extends Component {

    constructor(props){
        super(props);
        this.state = { openDialog: false };
    }

    handleOpenDialog = () => {
        this.setState({ openDialog: true });
    }

    handleCloseDialog = () => {
        this.setState({ openDialog: false });
    }  

    render() {
        return(
            <React.Fragment>
                <FramePesquisa
                    action={this.props.selecionarProduto}
                    dados={this.props.produtos}
                    desabilitado={this.props.desabilitado}
                    tamanho={3}
                    limite={100}
                    campos={['codAlt', 'descr']}
                    placeholder="Selecione um produto"
                    handleOpenDialog={this.handleOpenDialog}
                    {...this.props}
                />
                { this.state.openDialog && 
                    <FotoProduto openDialog={this.state.openDialog} id={this.props.default.id} handleClose={this.handleCloseDialog}/>
                }  
            </React.Fragment> 
        );
    }
}

function mapStateToProps(state) {        
    return {
        produtos: state.produtos        
    };
}

export default connect(mapStateToProps)(FrameProduto);