import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Chip from './custom_chip';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';
import TableHistorico from './table_historico_pedidos';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DateRange from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';

class Historico extends Component {
  constructor(props) {
    super(props);
    moment.locale('pt-BR');

    this.state = {
      historico: undefined,
      erro: false,
      loading: false,
      dataIni: moment().startOf('month'),
      dataFim: moment().endOf('month'),
      status: 'TODOS',
      total: 0,
      nroPedidos: 0,
      page: 0,
    };
    this.onDataIniChange = this.onDataIniChange.bind(this);
    this.onDataFimChange = this.onDataFimChange.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);
    this.obterHistorico = this.obterHistorico.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (_.isEmpty(this.props.parametros)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async obterHistorico(calculaTotal = true, page = 0) {
    this.setState({ loading: true });
    const idRep = this.props.parametros.idRep;
    if (idRep > 0) {
      const url = `${
        APIUtils.API_URL
      }/api/v1/pedidos/representante/web/${idRep}/${this.state.dataIni.format(
        'DD-MM-YYYY'
      )}/${this.state.dataFim.format('DD-MM-YYYY')}/${
        this.state.status !== 'TODOS' ? this.state.status : -1
      }?page=${page}&size=20`;
      try {
        const response = await axios.get(url);

        if (calculaTotal) {
          const urlTotal = `${
            APIUtils.API_URL
          }/api/v1/pedidos/representante/web/total/${idRep}/${this.state.dataIni.format(
            'DD-MM-YYYY'
          )}/${this.state.dataFim.format('DD-MM-YYYY')}/${
            this.state.status !== 'TODOS' ? this.state.status : -1
          }`;

          const responseTotal = await axios.get(urlTotal);

          this.setState({
            total: responseTotal.data.vlrTotal,
            nroPedidos: responseTotal.data.nroPedidos,
          });
        }

        if (this.mounted) {
          this.setState({
            historico: response.data,
            erro: false,
            loading: false,
          });
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({ loading: false, historico: null, erro: true });
        }
      }
    }
  }

  onDataIniChange(data) {
    this.setState({ dataIni: data });
  }

  onDataFimChange(data) {
    this.setState({ dataFim: data });
  }

  onStatusChange(e) {
    this.setState({ status: e.target.value });
  }

  onPageChange(e, newPage) {
    this.setState({ page: newPage });

    this.obterHistorico(false, newPage);
  }

  render() {
    const statusOptions = [
      'Em aberto',
      'Atendido',
      'Atendido Parcial',
      'Bloqueado',
      'Bloqueado Internet',
      'Bloqueado Comercial',
      'Bloqueado Financeiro',
      'Cancelado',
      'Em separação',
      'Separado',
      'Em conferência',
      'Conferido',
    ];
    return (
      <div>
        <div style={{ padding: '10px 10px' }}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale="pt-br"
          >
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  autoOk
                  label="Data inicial"
                  value={this.state.dataIni}
                  onChange={(date) => this.onDataIniChange(date)}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="Selecione uma data"
                        color="inherit"
                      >
                        <DateRange />
                      </IconButton>
                    ),
                  }}
                  format="DD/MM/YYYY"
                ></DatePicker>
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  autoOk
                  label="Data fim"
                  value={this.state.dataFim}
                  onChange={(date) => this.onDataFimChange(date)}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="Selecione uma data"
                        color="inherit"
                      >
                        <DateRange />
                      </IconButton>
                    ),
                  }}
                  format="DD/MM/YYYY"
                ></DatePicker>
              </Grid>

              <Grid item xs={12} md={4}>
                <FormControl
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'end',
                  }}
                >
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    label="Status"
                    value={this.state.status}
                    onChange={this.onStatusChange.bind(this)}
                  >
                    <MenuItem key="TODOS" value="TODOS">
                      Todos
                    </MenuItem>

                    {statusOptions.map((option, key) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {option}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
                style={{ display: 'flex', alignItems: 'end' }}
              >
                <IconButton
                  onClick={() => {
                    this.setState({ page: 0 });
                    this.obterHistorico();
                  }}
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>

        <div style={{ padding: '5px 5px' }}>
          <Link
            style={{
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
            }}
            to="/"
          >
            <NavigateBeforeIcon />
            <Typography variant="button">Voltar</Typography>
          </Link>
        </div>
        {this.state.historico !== undefined && (
          <>
            {this.state.loading ? (
              <div>Carregando...</div>
            ) : (
              <>
                {_.isEmpty(this.state.historico) ? (
                  <>
                    {this.state.erro ? (
                      <div>
                        <RefreshAction
                          descr="Histórico de Pedidos"
                          action={this.obterHistorico.bind(this)}
                        />
                      </div>
                    ) : (
                      <>
                        {this.state.historico === null ? (
                          <div>Nenhum dado encontrado...</div>
                        ) : (
                          <div>
                            <div>
                              <Chip label="Representante não possui nenhum histórico" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ padding: '10px 10px' }}>
                      <FormLabel
                        style={{
                          marginBottom: '10px',
                          fontSize: '0.8em',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        component="legend"
                      >
                        <div>Histórico de Pedidos</div>
                        <div>
                          Total: R${' '}
                          {this.state.total.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                      </FormLabel>
                      <TableHistorico
                        historico={this.state.historico}
                        statusOptions={statusOptions}
                        nroPedidos={this.state.nroPedidos}
                        page={this.state.page}
                        changePage={this.onPageChange}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { parametros: state.parametros };
}

export default connect(mapStateToProps, null)(Historico);
