import * as toastr from 'toastr';
import { OBTER_EMPRESAS, ADD_PROSPECT } from '../actions/index';
import _ from 'lodash';

export default function (state = {}, action) {
    switch (action.type) {
        case OBTER_EMPRESAS:                
            if(action.error){
                toastr.error("Erro obtendo empresas.");
                return state;                
            }else{           
                return action.payload;
            }
        case ADD_PROSPECT:
            if(_.isEmpty(state)){
                return state;
            }else{
                return state.concat([action.payload]);
            }            
        default:
            return state;
    }   
}
