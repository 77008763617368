import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ImplicitCallback } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import AuthOkta from './auth_okta';
import Dashboard from './components/dashboard';
import Historico from './components/historico';
import Notas from './components/notas';
import Metas from './components/metas';
import ResumoComissoes from './components/resumo_comissoes';
import Visita from './components/visita';
import Prospect from './components/prospect';
import ClientesAtraso from './components/clientes_atraso';
import Clientes from './components/clientes';

class Routes extends Component {
  render() {
    return (
      <React.Fragment>
        <AuthOkta>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/index.html" component={Dashboard} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/historico" component={Historico} />
          <Route path="/notas" component={Notas} />
          <Route exact path="/clientes" component={Clientes} />
          <Route exact path="/clientes/atraso" component={ClientesAtraso} />
          <Route path="/metas" component={Metas} />
          <Route path="/comissoes" component={ResumoComissoes} />
          <Route path="/visita" component={Visita} />
          <Route path="/prospect" component={Prospect} />
        </AuthOkta>
        <Route path="/implicit/callback" component={ImplicitCallback} />
      </React.Fragment>
    );
  }
}

export default withRouter(Routes);
