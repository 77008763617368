import React from 'react';
import Button from '@material-ui/core/Button';

const ButtonDropDown = props => {
    return (
        <Button 
            onClick={props.onClick} 
            size="small" 
            style={{  width: '100%', flex: 1, justifyContent: 'space-around' }}
        >
            {props.children}
            {props.label}
        </Button>
    )
} 

export default ButtonDropDown