import { combineReducers } from 'redux';
import ClientesReducer from './clientes_reducer';
import ProdutosReducer from './produtos_reducer';
import ParametrosReducer from './parametros_reducer';
import UnidadesReducer from './unidades_reducer';
import CondicoesReducer from './condicoes_reducer';
import ItensPedidoReducer from './itens_pedido_reducer';
import IpiCfsReducer from './ipi_cfs_reducer';
import TransportadorasReducer from './transportadoras_reducer';
import ProdutoSelecionadoReducer from './produto_selecionado_reducer';
import TranspSelecionadaReducer from './transp_selecionada_reducer';
import CondicaoSelecionadaReducer from './condicao_selecionada_reducer';
import PedidosPendentesReducer from './pedidos_pendentes_reducer';
import CompromissosReducer from './compromissos_reducer';
import EmpresasReducer from './empresas_reducer';
import ClienteSelecionadoReducer from './cliente_selecionado_reducer';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
    clientes: ClientesReducer,
    produtos: ProdutosReducer,
    parametros: ParametrosReducer,
    unidades: UnidadesReducer,
    condicoes: CondicoesReducer,
    itensPedido: ItensPedidoReducer,
    transportadoras: TransportadorasReducer,
    ipiCfs: IpiCfsReducer,
    produtoSelecionado: ProdutoSelecionadoReducer,
    transpSelecionada: TranspSelecionadaReducer,
    condicaoSelecionada : CondicaoSelecionadaReducer,
    pedidosPendentes: PedidosPendentesReducer,
    empresas: EmpresasReducer,
    compromissos: CompromissosReducer,
    form: formReducer,
    clienteSelecionado: ClienteSelecionadoReducer,
});

export default rootReducer;