import React, { useState } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { withStyles } from '@material-ui/core/styles'

const DropDownMenu = props => {

    const [open, setOpen] = useState(false)

    const handleClickAway = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(!open)
    }

    const { classes } = props

    return (
        <div className={classes.container}>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div>
                    <Button 
                        style={{ background: '#dbdbdd', minWidth: 0, margin: 0 }}
                        onClick={handleClick}
                    >
                        <ArrowDropDown />
                    </Button>
                    { open && (
                        <Paper className={classes.dropdown} >
                            {props.children}    
                        </Paper>
                    )}   
                </div>
            </ClickAwayListener>             
        </div>
    )
}

const styles = {
    container: {
        position: 'relative',
        display: 'inline-block'
    },
    dropdown: {
        display: 'block',
        position: 'absolute',
        top: '100%',
        right: 0,
        left: 'auto',
        minWidth: 120,
        listStyle: 'none',
        background: '#fff',
        zIndex: 1000,
        padding: 5,
        borderRadius: 3
    }
}

export default withStyles(styles)(DropDownMenu)