import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StorageIcon from '@material-ui/icons/Storage';
import RefreshIcon from '@material-ui/icons/Refresh';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Chart from '@material-ui/icons/InsertChartOutlined';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Work from '@material-ui/icons/Work';
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Business from '@material-ui/icons/Business';

class NavBar extends Component {
  toggleDrawer() {
    this.props.action();
  }

  atualizarPagina() {
    window.location.reload();
  }

  btnPedidosClick() {
    this.props.history.push('/historico');
  }

  btnClientesClick() {
    this.props.history.push('/clientes');
  }

  btnClienteAtrasoClick() {
    this.props.history.push('/clientes/atraso');
  }

  btnNotasClick() {
    this.props.history.push('/notas');
  }

  btnMetasClick() {
    this.props.history.push('/metas');
  }

  btnComissoesClick() {
    this.props.history.push('/comissoes');
  }

  btnVisitaClick() {
    this.props.history.push('/visita');
  }

  btnProspectClick() {
    this.props.history.push('/prospect');
  }

  logout() {
    this.props.auth.logout('/');
  }

  render() {
    return (
      <div>
        <Drawer
          anchor="left"
          open={this.props.open}
          onClose={this.toggleDrawer.bind(this)}
        >
          <div
            style={{ width: 250 }}
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer.bind(this)}
          >
            <List component="nav">
              <ListItem button onClick={this.btnPedidosClick.bind(this)}>
                <ListItemIcon>
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary="Pedidos" />
              </ListItem>
              <ListItem button onClick={this.btnNotasClick.bind(this)}>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                <ListItemText primary="Notas" />
              </ListItem>
              <ListItem button onClick={this.btnClientesClick.bind(this)}>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
              </ListItem>
              <ListItem button onClick={this.btnClienteAtrasoClick.bind(this)}>
                <ListItemIcon>
                  <WatchLaterIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes em Atraso" />
              </ListItem>
              <ListItem button onClick={this.btnMetasClick.bind(this)}>
                <ListItemIcon>
                  <Chart />
                </ListItemIcon>
                <ListItemText primary="Metas" />
              </ListItem>
              <ListItem button onClick={this.btnComissoesClick.bind(this)}>
                <ListItemIcon>
                  <Work />
                </ListItemIcon>
                <ListItemText primary="Comissões" />
              </ListItem>
              <ListItem button onClick={this.btnVisitaClick.bind(this)}>
                <ListItemIcon>
                  <SupervisedUserCircle />
                </ListItemIcon>
                <ListItemText primary="Nova visita" />
              </ListItem>

              <ListItem button onClick={this.btnProspectClick.bind(this)}>
                <ListItemIcon>
                  <Business />
                </ListItemIcon>
                <ListItemText primary="Prospect" />
              </ListItem>
              <ListItem button onClick={this.atualizarPagina.bind(this)}>
                <ListItemIcon>
                  <RefreshIcon />
                </ListItemIcon>
                <ListItemText primary="Atualizar" />
              </ListItem>
              <ListItem button onClick={this.logout.bind(this)}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </ListItem>
              <Divider />
              <ListItem style={{ textAlign: 'center', background: '#ccc' }}>
                <ListItemText primary="Versão 1.9.96" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withAuth(NavBar);
