import React, { Component } from 'react';
import { connect } from 'react-redux';
import FramePesquisa from './frame_pesquisa';

class FrameTransportadora extends Component {

    render() {
        return(
            <FramePesquisa 
                action={this.props.selecionarTransportadora}
                dados={this.props.transportadoras}
                desabilitado={this.props.desabilitado}
                tamanho={3}
                campos={['abrev', 'nome']}
                placeholder="Selecione uma transportadora"
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {    
    return {
        transportadoras: state.transportadoras,
    };
}

export default connect(mapStateToProps, null)(FrameTransportadora);


