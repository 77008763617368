import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import toastr from 'toastr';
import TextField from '@material-ui/core/TextField';
import { Field, reduxForm } from 'redux-form';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import APIUtils from '../utils/api_util';
import axios from 'axios';
import { mensagemErro } from '../utils/api_util';
import { addProspect } from  '../actions/index';

class Prospect extends Component {

	async salvarProspect(values) {
        const idRep = this.props.parametros.idRep;		
        try {                  
            const url = `${APIUtils.API_URL}/api/v1/prospect/${idRep}`;            
            const config = { headers: { 'Content-type': 'application/json' }, timeout: 8000};                
            const resp = await axios.post(url, JSON.stringify(values), config);
            toastr.info("Prospect salvo com sucesso!");      
            this.props.addProspect(values, resp.data);
            this.props.reset();
            this.props.history.push("/visita");
        } catch(e){  
            toastr.error("Erro salvando visita: " + mensagemErro(e));
        }        
	}


    renderTextField(field) {        
        const { input, label, meta: { touched, error }, ...custom } = field;
		return (
			<div>
				<TextField
			        fullWidth={true}
                    label={label}
                    error={touched && !!error} 
                    helperText={error}				
					{...input}
					{...custom} 
				/>
			</div>
		);
    }
    
    upper(value) {
        return value && value.toUpperCase();
    }

    render(){
        const { handleSubmit, invalid } = this.props;        
        return (
            <div>
                <form onSubmit={handleSubmit(this.salvarProspect.bind(this))}>
                    <Field name="nome" component={this.renderTextField} label="Nome" normalize={this.upper}/>
                    <Field name="nomeAbreviado" component={this.renderTextField} label="Nome Fantasia" normalize={this.upper} />
                    <Field name="cpfCnpj" component={this.renderTextField} label="CNPJ/CPF (apenas números)" type="number" normalize={this.upper} />
                    <Field name="ieRg" component={this.renderTextField} label="IE/RG" />
                    <Field name="email" component={this.renderTextField} label="E-mail" normalize={this.upper} />
                    <Field name="cep" component={this.renderTextField} label="CEP (apenas números)" type="number" />
                    <Field name="fone" component={this.renderTextField} label="Fone" />
                    <Field name="fone2" component={this.renderTextField} label="Fone 2" />
                    <Field name="nroFunc" component={this.renderTextField} label="Número de funcionários" />
                    <Field name="ramo" component={this.renderTextField} label="Ramo" normalize={this.upper}/>
                    <Field name="fornecedorAtual" component={this.renderTextField} label="Fornecedor atual" normalize={this.upper} />
                    <Field name="produtosUtilizados" component={this.renderTextField} label="Produtos utilizados" normalize={this.upper}/>
                    <Field name="gastoFornecAtual" component={this.renderTextField} label="Gastos fornecedor atual" normalize={this.upper} />
                    <Field name="estimativaConsumo" component={this.renderTextField} label="Estimativa consumo" normalize={this.upper}/>
                    <Button style={{ marginTop: 8, marginBottom: 8 }} type="submit" disabled={invalid} variant="outlined"color="primary" >Salvar</Button>                    
                </form>
                <Link style={{  display: 'flex', textDecoration: 'none', alignItems: 'center' }} to="/">
                    <NavigateBeforeIcon /> 
                     <Typography variant="button">Voltar</Typography>
                </Link>
            </div>
        );
    }

}

function validate(values, props) {
	const errors = {};
	const campos = [ 'nome', 'nomeAbreviado', 'cpfCnpj', 'ieRg', 'email', 'cep', 'fone', 'fone2', 'nroFunc', 'ramo', 'fornecedorAtual', 'produtosUtilizados', 'estimativaConsumo' ];
	const tamanhos = [ 40, 20, 18, 18, 50, 9, 15, 15, 255, 255, 255, 400, 255 ];

	for(var i = 0; i < campos.length; i++) {
		const field = campos[i];
	    if (values[field] && values[field].length > tamanhos[i]) {
      		errors[field] = 'Campo ultrapassa o limite máximo de ' + tamanhos[i] + ' caracteres.';
		}
	}
		  
	if(!values.nome && !values.nomeAbreviado){
		errors.nome = 'É preciso informar uma Razão social ou Nome fantasia';
	}

	 if(values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
	 	errors.email = 'Endereço de e-mail inválido';
	 }

		
	return errors;
}

function mapStateToProps(state) {
    return { parametros: state.parametros }
}

Prospect = reduxForm({
    form: 'formProspect',
    validate,
 })(Prospect)

export default connect(mapStateToProps, { addProspect })(Prospect);