import React from 'react'
import Button from '@material-ui/core/Button';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';

const ButtonUpDown = (props) => {

    return(
        <div>
            <Button style={props.style} size="small" color="primary" onClick={ props.onClick }>
                { props.label }
                { props.exibe ? <UpIcon /> : <DownIcon /> }
            </Button>                              
        </div>
    )
}

export default ButtonUpDown