import React, { Component } from 'react';
import { connect } from 'react-redux';
import FramePesquisa from './frame_pesquisa';
import _ from 'lodash';
import { obterEmpresas } from '../actions/index' 

class FrameEmpresa extends Component {

    componentDidMount() {
        if(_.isEmpty(this.props.empresas)){            
            this.props.obterEmpresas();
        }
    }

    render() {
        if(_.isEmpty(this.props.empresas)){
            return <div>Carregando...</div>    
        }
        return(
            <FramePesquisa 
                action={this.props.selecionarEmpresa}
                dados={this.props.empresas}
                desabilitado={this.props.desabilitado}
                tamanho={2}
                campos={['nomeAbreviado', 'nome']}
                placeholder="Selecione uma empresa"
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {    
    return {
        empresas: state.empresas,
    };
}

export default connect(mapStateToProps, { obterEmpresas })(FrameEmpresa);