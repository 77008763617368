import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const TableClientesAtraso = (props) => {
  const { classes } = props;

  function renderHeader() {
    return (
      <TableHead className={classes.thead}>
        <TableRow className={classes.tbodyTr}>
          <TableCell className={classes.tableCell}>Número</TableCell>
          <TableCell className={classes.tableCell}>Parcela</TableCell>
          <TableCell className={classes.tableCell}>Data Emi.</TableCell>
          <TableCell className={classes.tableCell}>Data Vtcto.</TableCell>
          <TableCell className={classes.tableCell}>Cliente</TableCell>
          <TableCell className={classes.tableCell} align="right">
            Valor (R$)
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function renderBody() {
    if (!props.clientes) {
      return null;
    }

    return (
      <TableBody>
        {props.clientes.map((cliente) => {
          return (
            <TableRow key={cliente.id} className={classes.tbodyTr}>
              <TableCell className={classes.tableCell}>
                {cliente.numDoc}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {cliente.numParc}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {cliente.dtEmis}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {cliente.dtVenc}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {cliente.nome}
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                {cliente.vlrSaldo.toLocaleString('pt-br', {
                  minimumFractionDigits: 2,
                })}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  return (
    <Paper className={classes.root}>
      <Table>
        {renderHeader()}
        {renderBody()}
        <TablePagination
          count={props.nroClientes}
          labelDisplayedRows={({ from, to, count }) =>
            `${from} - ${to} de ${count}`
          }
          component="div"
          page={props.page}
          rowsPerPage={20}
          rowsPerPageOptions={[]}
          onChangePage={props.changePage}
          backIconButtonText="Página Anterior"
          nextIconButtonText="Próxima Página"
        />
      </Table>
    </Paper>
  );
};

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },

  status: {
    textAlign: 'center',
    color: '#fff',
    fontSize: '0.75em',
    fontWeight: 'bold',
    borderRadius: '10px',
    padding: 7,
  },

  thead: {
    background: '#ccc',
  },

  theadTr: {
    height: '30px',
  },

  tbodyTr: {
    height: '30px',
  },

  tableCell: {
    padding: '4px 4px 4px 4px',
    '&:last-of-type': { paddingRight: '4px' },
    '& button': { width: 20, height: 20 },
    '& button > span > svg': { fontSize: 18 },
  },
};

export default withStyles(styles)(TableClientesAtraso);
