import React, { Component } from 'react';
import axios from 'axios';
import API from '../utils/api_util' 
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Chip from './custom_chip';
import RefreshAction from './refresh_action';
import _ from 'lodash';

import { 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer,
    Bar,
    BarChart,
    Legend,
} from 'recharts';

class Metas extends Component {

    constructor(props){
        super(props);
        this.state = { metas: [], erro: false };
        this.loadMetas = this.loadMetas.bind(this);
    }

    componentDidMount() {
        this.mounted = true;
        if (_.isEmpty(this.props.parametros)) {
            this.props.history.push("/");
        }        
        this.loadMetas();
    }

    componentWillUnmount(){
        this.mounted = false;
    }        
    
    async loadMetas() {        
        const { idRep } = this.props.parametros;
        if (idRep > 0) {
            try {
                const url = `${API.API_URL}/api/v1/quotas/resumo/${idRep}`;
                const meta = await axios.get(url);   
                if (this.mounted){ 
                    this.setState({ metas: [meta.data] })
                }
            } catch(e) {
                if(this.mounted) {
                    this.setState({ metas: [], erro: true });
                }
            }
        }        
    }

    render() {
        const { classes } = this.props
        if (!_.isEmpty(this.state.metas)) {
            return (
                <div>
                    <div className={classes.container}>
                        <div style={{ textAlign: 'center', padding: 10 }}>
                            <Typography variant="h5">Resumo de quotas do mês </Typography>
                        </div>                        

                        <ResponsiveContainer>
                            <BarChart data={this.state.metas}  layout="vertical">
                                <CartesianGrid stroke='#ccc'/>
                                <XAxis type="number"/>
                                <YAxis dataKey="id" type="category"/>
                                <Tooltip/>
                                <Legend />
                                <Bar formatter={value => value.toLocaleString()} dataKey="previsto" fill="#8884d8" />
                                <Bar formatter={value => value.toLocaleString()} dataKey="realizado" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                        <div style={{ padding: 10 }}>
                            <Link style={{  display: 'flex', textDecoration: 'none', alignItems: 'center' }} to="/">
                                <NavigateBeforeIcon /> 
                                <Typography variant="button">Voltar</Typography>
                            </Link>
                        </div>
                    </div>
                </div>
            ) 
        } else {
            if (this.state.erro) {                
                return ( 
                    <div>
                        <RefreshAction descr="Resumo de quotas do mês" action={this.loadMetas}/>
                        <div style={{ padding: 10 }}>
                            <Link style={{  display: 'flex', textDecoration: 'none', alignItems: 'center' }} to="/">
                                <NavigateBeforeIcon /> 
                                <Typography variant="button">Voltar</Typography>
                            </Link>
                        </div>
                    </div>
                )       
            } else {
                if (_.isEmpty(this.state.metas)) {
                    return(
                        <div>
                            <div>Carregando...</div>
                        </div>
                    );
                }  else {
                    return (
                        <div style={{ padding: 10 }}>
                            <Chip label="Representante não possui nenhuma meta" />
                            <Link style={{  display: 'flex', textDecoration: 'none', alignItems: 'center' }} to="/">
                                <NavigateBeforeIcon /> 
                                <Typography variant="button">Voltar</Typography>
                            </Link>
                        </div>
                    )   
                } 
            }
        }       
    }
}

function mapStateToProps(state) {
    return { parametros: state.parametros }
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        margin: 0,
        width: 'calc(100vw - 30px)',
        height: 'calc(100vh - 30px)',
        background: '#fff'
    }
}

export default connect(mapStateToProps, null)(withStyles(styles)(Metas));