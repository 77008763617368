import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Chip from './custom_chip';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import TableClientes from './table_clientes';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

class Clientes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: '',
      cidade: '',
      clientes: undefined,
      erro: false,
      loading: false,
      nroClientes: 0,
      page: 0,
    };
    this.obterClientes = this.obterClientes.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onNomeChange = this.onNomeChange.bind(this);
    this.onCidadeChange = this.onCidadeChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (_.isEmpty(this.props.parametros)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async obterClientes() {
    this.setState({ loading: true });
    const idRep = this.props.parametros.idRep;
    if (idRep > 0) {
      try {
        const clientes =
          this.state.nome !== '' || this.state.cidade !== ''
            ? this.props.clientes.filter((el) => {
                return el.nome.includes(this.state.nome.toUpperCase()) && 
                        el.cidade.includes(this.state.cidade.toUpperCase());
              })
            : this.props.clientes;

        if (this.mounted) {
          this.setState({
            clientes: clientes,
            nroClientes: clientes.length,
            erro: false,
            loading: false,
          });
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({
            loading: false,
            clientes: null,
            nroClientes: 0,
            erro: true,
          });
        }
      }
    }
  }

  onNomeChange(e) {
    this.setState({ nome: e.target.value });
  }

  onCidadeChange(e) {
    this.setState({ cidade: e.target.value });
  }

  onPageChange(e, newPage) {
    this.setState({ page: newPage });
  }

  render() {
    return (
      <div>
        <div style={{ padding: '10px 10px' }}>
          <Grid container spacing={2} style={{ padding: 10 }}>
            <Grid item xs={12} md={3}>
              <TextField
                value={this.state.nome}
                onChange={this.onNomeChange}
                label="Nome"
                id="nome"
                name="nome"
                variant="outlined"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                value={this.state.cidade}
                onChange={this.onCidadeChange}
                label="Cidade"
                id="cidade"
                name="cidade"
                variant="outlined"
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: 'flex', alignItems: 'end' }}
            >
              <IconButton
                onClick={() => {
                  this.setState({ page: 0 });
                  this.obterClientes();
                }}
                color="primary"
              >
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: '5px 5px' }}>
          <Link
            style={{
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
            }}
            to="/"
          >
            <NavigateBeforeIcon />
            <Typography variant="button">Voltar</Typography>
          </Link>
        </div>
        {this.state.clientes !== undefined && (
          <>
            {this.state.loading ? (
              <div>Carregando...</div>
            ) : (
              <>
                {_.isEmpty(this.state.clientes) ? (
                  <>
                    {this.state.erro ? (
                      <div>
                        <RefreshAction
                          descr="Histórico de Clientes"
                          action={this.obterClientes.bind(this)}
                        />
                      </div>
                    ) : (
                      <>
                        {this.state.clientes === null ? (
                          <div>Nenhum dado encontrado...</div>
                        ) : (
                          <div>
                            <div>
                              <Chip label="Representante não possui nenhum cliente" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ padding: '10px 10px' }}>
                      <FormLabel
                        style={{
                          marginBottom: '10px',
                          fontSize: '0.8em',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        component="legend"
                      >
                        <div>Histórico de Clientes</div>
                        <div>Total de Clientes: {this.state.nroClientes}</div>
                      </FormLabel>
                      <TableClientes
                        clientes={this.state.clientes}
                        nroClientes={this.state.nroClientes}
                        page={this.state.page}
                        changePage={this.onPageChange}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    parametros: state.parametros,
    clientes: state.clientes,
  };
}

export default connect(mapStateToProps, null)(Clientes);
