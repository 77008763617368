import * as toastr from 'toastr'
import { OBTER_CLIENTES } from '../actions/index'

export default function (state = {}, action) {
  switch (action.type) {
    case OBTER_CLIENTES:
      if (action.error) {
        toastr.error('Erro obtendo clientes.')
        return state
      } else {
        let array = action.payload
        array.forEach((cliente, i) => {
          if (cliente.cpfCnpj?.length === 11) {
            array[i].cpfCnpjFormatado = cliente.cpfCnpj?.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})/,
              '$1.$2.$3-$4'
            )
          } else {
            array[i].cpfCnpjFormatado = cliente.cpfCnpj?.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5'
            )
          }
        })
        return array
      }
    default:
      return state
  }
}
