import React, { Component } from 'react'
import axios from 'axios'
import API from '../utils/api_util'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import Chip from './custom_chip'
import RefreshAction from './refresh_action'
import _ from 'lodash'
import DetalhesComissoes from './detalhes_comissoes.js'
import { CircularProgress } from '@material-ui/core'

class ResumoComissoes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comissoes: {},
      erro: false,
      dados: [],
      tipo: undefined,
      carregando: false,
    }
    this.loadComissoes = this.loadComissoes.bind(this)
    this.obterLiberados = this.obterLiberados.bind(this)
    this.obterPrevistos = this.obterPrevistos.bind(this)
    this.obterPrevistosMeses = this.obterPrevistosMeses.bind(this)
    this.obterAtrasados = this.obterAtrasados.bind(this)
    this.obterAtrasadosMeses = this.obterAtrasadosMeses.bind(this)
  }

  componentDidMount() {
    this.mounted = true
    if (_.isEmpty(this.props.parametros)) {
      this.props.history.push('/')
    }
    this.loadComissoes()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  async loadComissoes() {
    const { idRep } = this.props.parametros
    if (idRep > 0) {
      try {
        const url = `${API.API_URL}/api/v1/comissoes/resumo/${idRep}`
        const resp = await axios.get(url)
        if (this.mounted) {
          this.setState({ comissoes: resp.data })
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({ comissoes: {}, erro: true })
        }
      }
    }
  }

  async obterLiberados() {
    this.setState({ carregando: true })
    try {
      const url = `${API.API_URL}/api/v1/comissoes/liberadas/${this.props.parametros.idRep}`
      const resp = await axios.get(url)
      if (this.mounted) {
        this.setState({ dados: resp.data, tipo: 'Comissões Liberadas' })
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ dados: {}, erro: true })
      }
    }
    this.setState({ carregando: false })
  }

  async obterPrevistos() {
    this.setState({ carregando: true })
    try {
      const url = `${API.API_URL}/api/v1/comissoes/previstas/${this.props.parametros.idRep}`
      const resp = await axios.get(url)
      if (this.mounted) {
        this.setState({ dados: resp.data, tipo: 'Comissões Previstas' })
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ dados: {}, erro: true })
      }
    }
    this.setState({ carregando: false })
  }

  async obterPrevistosMeses() {
    this.setState({ carregando: true })
    try {
      const url = `${API.API_URL}/api/v1/comissoes/previstas/meses/${this.props.parametros.idRep}`
      const resp = await axios.get(url)
      if (this.mounted) {
        this.setState({
          dados: resp.data,
          tipo: 'Comissões Para os Próximos Meses',
        })
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ dados: {}, erro: true })
      }
    }
    this.setState({ carregando: false })
  }

  async obterAtrasados() {
    this.setState({ carregando: true })
    try {
      const url = `${API.API_URL}/api/v1/comissoes/atrasadas/${this.props.parametros.idRep}`
      const resp = await axios.get(url)
      if (this.mounted) {
        this.setState({
          dados: resp.data,
          tipo: 'Comissões Atrasadas Menos de 60 Dias',
        })
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ dados: {}, erro: true })
      }
    }
    this.setState({ carregando: false })
  }

  async obterAtrasadosMeses() {
    this.setState({ carregando: true })
    try {
      const url = `${API.API_URL}/api/v1/comissoes/atrasadas/meses/${this.props.parametros.idRep}`
      const resp = await axios.get(url)

      if (this.mounted) {
        this.setState({
          dados: resp.data,
          tipo: 'Comissões Atrasadas Mais de 60 Dias',
        })
      }
    } catch (e) {
      if (this.mounted) {
        this.setState({ dados: {}, erro: true })
      }
    }
    this.setState({ carregando: false })
  }

  render() {
    const { classes } = this.props
    if (!_.isEmpty(this.state.comissoes)) {
      const {
        dataBase,
        liberado,
        previsto,
        previstoProxMeses,
        atrasoAte60Dias,
        atrasoAcima60Dias,
      } = this.state.comissoes

      return (
        <div>
          <div className={classes.container}>
            <div style={{ textAlign: 'center', padding: 10 }}>
              <Typography variant="h5">Resumo das comissões </Typography>
            </div>
            <Typography>Data base: {dataBase} </Typography>
            <Typography
              onClick={this.obterLiberados}
              className={classes.clickable}
            >
              Liberado:{' '}
              {Number(liberado).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
            </Typography>
            <Typography
              onClick={this.obterPrevistos}
              className={classes.clickable}
            >
              Previsto:{' '}
              {Number(previsto).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
            </Typography>
            <Typography
              onClick={this.obterPrevistosMeses}
              className={classes.clickable}
            >
              Previsto próximos meses:{' '}
              {Number(previstoProxMeses).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
            </Typography>
            <Typography
              onClick={this.obterAtrasados}
              className={classes.clickable}
            >
              Atrasados até 60 dias:{' '}
              {Number(atrasoAte60Dias).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
            </Typography>
            <Typography
              onClick={this.obterAtrasadosMeses}
              className={classes.clickable}
            >
              Atrasados acima 60 dias:{' '}
              {Number(atrasoAcima60Dias).toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}{' '}
            </Typography>
            <div style={{ padding: 10 }}>
              <Link
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  alignItems: 'center',
                }}
                to="/"
              >
                <NavigateBeforeIcon />
                <Typography variant="button">Voltar</Typography>
              </Link>
            </div>
            {this.state.carregando && (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            )}

            {this.state.dados.length > 0 && !this.state.carregando && (
              <DetalhesComissoes
                dados={this.state.dados}
                tipo={this.state.tipo}
              />
            )}
          </div>
        </div>
      )
    } else {
      if (this.state.erro) {
        return (
          <div>
            <RefreshAction
              descr="Resumo das comissões"
              action={this.loadComissoes}
            />
            <div style={{ padding: 10 }}>
              <Link
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  alignItems: 'center',
                }}
                to="/"
              >
                <NavigateBeforeIcon />
                <Typography variant="button">Voltar</Typography>
              </Link>
            </div>
          </div>
        )
      } else {
        if (_.isEmpty(this.state.comissoes)) {
          return <div>Carregando...</div>
        } else {
          return (
            <div style={{ padding: 10 }}>
              <Chip label="Representante não possui possuí nenhuma comissão" />
              <Link
                style={{
                  display: 'flex',
                  textDecoration: 'none',
                  alignItems: 'center',
                }}
                to="/"
              >
                <NavigateBeforeIcon />
                <Typography variant="button">Voltar</Typography>
              </Link>
            </div>
          )
        }
      }
    }
  }
}

function mapStateToProps(state) {
  return { parametros: state.parametros }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
    width: 'calc(100vw - 30px)',
    height: 'calc(100vh - 30px)',
    background: '#fff',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  loadingContainer: {
    textAlign: 'center',
    width: '100%',
    margin: 10,
  },
}

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(ResumoComissoes))
