import * as toastr from 'toastr';
import { OBTER_COMPROMISSOS, REMOVER_COMPROMISSO } from '../actions/index';

export default function (state = {}, action) {
    switch (action.type) {
        case OBTER_COMPROMISSOS:                
            if(action.error){
                toastr.error("Erro obtendo compromissos.");
                return state;                
            }else{           
                return action.payload;
            }
        case REMOVER_COMPROMISSO: 
            const compromissos = state.filter(compromisso => compromisso.id !== action.payload);
            return compromissos;
        default:
            return state;
    }   
}
