import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Chip from './custom_chip';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import moment from 'moment';
import TableNotas from './table_notas';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import DateRange from '@material-ui/icons/DateRange';
import SearchIcon from '@material-ui/icons/Search';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Notas extends Component {
  constructor(props) {
    super(props);
    moment.locale('pt-BR');

    this.state = {
      notas: undefined,
      erro: false,
      loading: false,
      dataIni: moment().startOf('month'),
      dataFim: moment().endOf('month'),
      apenasVendas: true,
      total: 0,
      nroNotas: 0,
      page: 0,
    };
    this.onDataIniChange = this.onDataIniChange.bind(this);
    this.onDataFimChange = this.onDataFimChange.bind(this);
    this.onApenasVendasChange = this.onApenasVendasChange.bind(this);
    this.obterNotas = this.obterNotas.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    if (_.isEmpty(this.props.parametros)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async obterNotas(calculaTotal = true, page = 0) {
    this.setState({ loading: true });
    const idRep = this.props.parametros.idRep;
    if (idRep > 0) {
      const url = `${
        APIUtils.API_URL
      }/api/v1/historico/vendas/notas/${idRep}/${this.state.dataIni.format(
        'DD-MM-YYYY'
      )}/${this.state.dataFim.format('DD-MM-YYYY')}/${
        this.state.apenasVendas
      }?page=${page}&size=20`;
      try {
        const response = await axios.get(url);

        if (calculaTotal) {
          const urlTotal = `${
            APIUtils.API_URL
          }/api/v1/historico/vendas/notas/total/${idRep}/${this.state.dataIni.format(
            'DD-MM-YYYY'
          )}/${this.state.dataFim.format('DD-MM-YYYY')}/${
            this.state.apenasVendas
          }`;

          const responseTotal = await axios.get(urlTotal);

          this.setState({
            total: responseTotal.data.vlrTotal,
            nroNotas: responseTotal.data.nroNotas,
          });
        }

        if (this.mounted) {
          this.setState({
            notas: response.data,
            erro: false,
            loading: false,
          });
        }
      } catch (e) {
        if (this.mounted) {
          this.setState({ loading: false, notas: null, erro: true });
        }
      }
    }
  }

  onDataIniChange(data) {
    this.setState({ dataIni: data });
  }

  onDataFimChange(data) {
    this.setState({ dataFim: data });
  }

  onApenasVendasChange(e) {
    this.setState({ apenasVendas: e.target.checked });
  }

  onPageChange(e, newPage) {
    this.setState({ page: newPage });

    this.obterNotas(false, newPage);
  }

  render() {
    return (
      <div>
        <div style={{ padding: '10px 10px' }}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            libInstance={moment}
            locale="pt-br"
          >
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={12} md={3}>
                <DatePicker
                  autoOk
                  label="Data inicial"
                  value={this.state.dataIni}
                  onChange={(date) => this.onDataIniChange(date)}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="Selecione uma data"
                        color="inherit"
                      >
                        <DateRange />
                      </IconButton>
                    ),
                  }}
                  format="DD/MM/YYYY"
                ></DatePicker>
              </Grid>

              <Grid item xs={12} md={3}>
                <DatePicker
                  autoOk
                  label="Data fim"
                  value={this.state.dataFim}
                  onChange={(date) => this.onDataFimChange(date)}
                  style={{ width: '100%' }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="Selecione uma data"
                        color="inherit"
                      >
                        <DateRange />
                      </IconButton>
                    ),
                  }}
                  format="DD/MM/YYYY"
                ></DatePicker>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{ display: 'flex', alignItems: 'end' }}
              >
                <IconButton
                  onClick={() => {
                    this.setState({ page: 0 });
                    this.obterNotas();
                  }}
                  color="primary"
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="apenasVendas"
                      checked={this.state.apenasVendas}
                      onChange={this.onApenasVendasChange}
                    />
                  }
                  label="Apenas vendas"
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ padding: '5px 5px' }}>
          <Link
            style={{
              display: 'flex',
              textDecoration: 'none',
              alignItems: 'center',
            }}
            to="/"
          >
            <NavigateBeforeIcon />
            <Typography variant="button">Voltar</Typography>
          </Link>
        </div>
        {this.state.notas !== undefined && (
          <>
            {this.state.loading ? (
              <div>Carregando...</div>
            ) : (
              <>
                {_.isEmpty(this.state.notas) ? (
                  <>
                    {this.state.erro ? (
                      <div>
                        <RefreshAction
                          descr="Histórico de Notas"
                          action={this.obterNotas.bind(this)}
                        />
                      </div>
                    ) : (
                      <>
                        {this.state.notas === null ? (
                          <div>Nenhum dado encontrado...</div>
                        ) : (
                          <div>
                            <div>
                              <Chip label="Representante não possui nenhuma nota" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div style={{ padding: '10px 10px' }}>
                      <FormLabel
                        style={{
                          marginBottom: '10px',
                          fontSize: '0.8em',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                        component="legend"
                      >
                        <div>Histórico de Notas</div>
                        <div>
                          Total: R${' '}
                          {this.state.total.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                          })}
                        </div>
                      </FormLabel>
                      <TableNotas
                        notas={this.state.notas}
                        nroNotas={this.state.nroNotas}
                        page={this.state.page}
                        changePage={this.onPageChange}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { parametros: state.parametros };
}

export default connect(mapStateToProps, null)(Notas);
