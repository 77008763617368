import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import TableItens from './table_itens_pedido'
import { removerItem } from '../actions/index';
import { selecionarProduto } from  '../actions/index';

class ItensPedido extends Component {

    excluirItem = dado => {
        this.props.removerItem(dado);
    }

    editarItem = dado => {
        this.props.removerItem(dado);
        const prod = { ...dado.prod, vlrOrig: dado.prod.vlrVenda, vlrVenda: dado.vlrUnit, qtde: dado.qtde, desconto: dado.alqDesc, consFin: dado.consFin }
        this.props.selecionarProduto(prod);
    }

    render() {
        return(
            <div>
                <FormLabel style={{marginBottom: '10px'}} component="legend">Itens do pedido</FormLabel>                                                                                
                <TableItens itens={this.props.itensPedido} excluir={this.excluirItem} editar={this.editarItem}/>
            </div>                
        );
    }
}

function mapStateToProps(state) {
    return {
        itensPedido: state.itensPedido
    };
}

export default connect(mapStateToProps, { removerItem, selecionarProduto })(ItensPedido);