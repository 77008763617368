import React, { useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core'
import createTheme from '@material-ui/core/styles/createMuiTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { ptBR } from '@material-ui/core/locale'
import { withStyles } from '@material-ui/core/styles'

const DetalhesComissoes = (props) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const theme = createTheme({}, ptBR)
  const { classes } = props

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }

  function handleChangeRowsPerPage(event) {
    setPage(0)
    setRowsPerPage(event.target.value)
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5">{props.tipo}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Cliente</TableCell>
            <TableCell className={classes.tableHead}>
              Número do Documento
            </TableCell>
            <TableCell className={classes.tableHead}>Data de Emissão</TableCell>
            <TableCell className={classes.tableHead}>
              Data de Pagamento
            </TableCell>
            <TableCell className={classes.tableHead}>
              Data de Vencimento
            </TableCell>
            <TableCell className={classes.tableHead}>
              Valor da Comissão
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.dados
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((comis, key) => {
              return (
                <TableRow key={key} className={classes.tableRow}>
                  <TableCell>{comis.cliente}</TableCell>
                  <TableCell>{comis.nroDoc}</TableCell>
                  <TableCell>{comis.dataEmi}</TableCell>
                  <TableCell>{comis.dataPgto}</TableCell>
                  <TableCell>{comis.dataVenc}</TableCell>
                  <TableCell>
                    {comis.vlrComissao.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
      <ThemeProvider theme={theme}>
        <TablePagination
          rowsPerPageOptions={[
            10,
            50,
            100,
            {
              value: props.dados.length,
              label: 'Mostrar Todas',
            },
          ]}
          component="div"
          count={props.dados.length}
          rowsPerPage={rowsPerPage || 0}
          labelRowsPerPage="linhas por página"
          page={page || 0}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          backIconButtonProps={{
            'aria-label': 'Página Anterior',
          }}
          nextIconButtonProps={{
            'aria-label': 'Próxima Página',
          }}
        />
      </ThemeProvider>
    </div>
  )
}

const styles = {
  container: {
    textAlign: 'center',
    padding: 10,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#eee',
    },
  },
}

export default withStyles(styles)(DetalhesComissoes)
