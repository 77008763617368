import React, { Component } from 'react';
import FrameEmpresa from './frame_empresa';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import toastr from 'toastr';
import axios from 'axios';
import { mensagemErro } from '../utils/api_util';
import APIUtils from '../utils/api_util';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Chip from './custom_chip'

class Visita extends Component {

    constructor(props){
        super(props);
        this.salvando = false;
        this.state = { empresa: {}, obs: '' };
        this.salvarVisita = this.salvarVisita.bind(this);
        this.setEmpresa = this.setEmpresa.bind(this);
        this.onObsChange = this.onObsChange.bind(this);
        this.renderInfo = this.renderInfo.bind(this);
    }

    setEmpresa(empresa) {
        if(!_.isEmpty(empresa)) {
            this.setState( { empresa });
        } else {
            this.setState( { empresa: {} });
        }
    }    

    onObsChange(event) {
        this.setState({ obs: event.target.value });
    }
    

    async salvarVisita() {
        if(this.salvando){
            toastr.warning("Visita já está sendo salva!");
            return;
        }        
        this.salvando = true;
        if(_.isEmpty(this.props.parametros) || this.props.parametros.idRep <= 0){
            toastr.error("Erro com as informações de representante");
            this.salvando = false;            
            return;
        }
        if(_.isEmpty(this.state.empresa)) {
            toastr.error("É preciso selecionar uma empresa para salvar a visita");
            this.salvando = false;            
            return;
        }
        if(this.state.obs === '') {
            toastr.error("É preciso informar uma observação");
            this.salvando = false;            
            return;
        }        
        const visita = {
            idRep: this.props.parametros.idRep,
            obs: this.state.obs,
            guid: new Date().getTime()
        }
        if(this.state.empresa.tipo === 0){
            // Cliente
            visita['idCli'] = this.state.empresa.idEmp;
        }else{
            // Prospect
            visita['idProsp'] = this.state.empresa.idEmp;
        }

        try {                  
            const url = `${APIUtils.API_URL}/api/v1/visitas`;            
            const config = { headers: { 'Content-type': 'application/json' }, timeout: 8000};                
            await axios.post(url, JSON.stringify(visita), config);
            toastr.info("Visita salva com sucesso!");      
            this.setState({ empresa: {}, obs: '' });
        } catch(e){  
            toastr.error("Erro salvando visita: " + mensagemErro(e));
        } finally{
            this.salvando = false;
        }      
        
               	        
    }

    renderInfo() {
        if(_.isEmpty(this.state.empresa)){
            return null;
        }
        const tipo = this.state.empresa.tipo === 0 ? 'Cliente' : 'Prospect';
        return <div><Chip label={tipo}/></div>                    
    }

	render() {
        return (
            <React.Fragment>
                <FrameEmpresa selecionarEmpresa={this.setEmpresa.bind(this)} default={this.state.empresa} />
                {this.renderInfo()} 
                <div style={{ padding: 10 }} >
                    <TextField
                        value={this.state.obs}
                        onChange={this.onObsChange}
                        multiline={true}
                        placeholder="Observações"  
                        fullWidth={true}>
                    </TextField>
                </div>    
                   
                <Link style={{  display: 'flex', textDecoration: 'none', alignItems: 'center' }} to="/">
                    <NavigateBeforeIcon /> 
                     <Typography variant="button">Voltar</Typography>
                </Link>                
                <div style={{ padding: 10, textAlign: 'right' }}>
                    <Button variant="contained" size="small" color="primary" onClick={this.salvarVisita} >
                     Salvar visita
                    </Button>
                </div>

            </React.Fragment>                
        );
	}
}

function mapStateToProps(state) {    
    return {
        parametros: state.parametros
    };
}

export default connect(mapStateToProps, null)(Visita);