import React, { useEffect } from 'react';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import moment from 'moment';
import _ from 'lodash';

const Tracking = props => {    
    useEffect(() => {
        async function sucesso(position) {
            try{                  
                const url = `${APIUtils.API_URL}/api/v1/geocoding/tracking`;            
                const config = { headers: { 'Content-type': 'application/json' }, timeout: 8000 };                
                const tracking = {
                    idRep: props.idRep,
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                    dataHora: moment().format('DD-MM-YYYY HH:mm:ss')
                }
                await axios.post(url, JSON.stringify(tracking), config);
            } catch(e){  
                console.log(e);
            }      

        }

        function erro() {
            console.log("deu erro");
        }
        const gps_options = { enableHighAccuracy: true, maximumAge: 0, timeout: 15000 };    
        var watchID = -1;
        const delay = 120000// Sera salva a posicao a cada 2 minutos
        watchID =  navigator.geolocation.watchPosition(_.throttle(sucesso, delay), erro, gps_options);
        return () => {
            navigator.geolocation.clearWatch(watchID);
        }
    }, [props.idRep]);     

    return (
        <div/>
    )
}


export default Tracking;