import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import PedidoItem from './pedido_item';
import HistoricoVendaCliProd from './historico_venda_cli_prod'
import FrameProduto from './frame_produto'
import { connect } from 'react-redux';
import _ from 'lodash';
import ItensPedido from './itens_pedido';
import { selecionarProduto } from  '../actions/index';

class PedidoProd extends Component {
        
    setProduto(prod){
        if(!_.isEqual(prod, this.props.produtoSelecionado)){
            this.props.selecionarProduto(prod);
        }
    }

    limparItem(){
        this.setProduto({});
    }

    render() {
        const { classes } = this.props;
        const idCli = _.isEmpty(this.props.cliente) ? -1 : this.props.cliente.id;
        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary  className={ classes.expansionPanelSummary } expandIcon={ <ExpandMoreIcon /> }>
                        <Typography>Produtos</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                        <div style={{ padding: '10px 10px' }}>
                            <div style={{ marginBottom: '20px' }}>
                                <FrameProduto selecionarProduto={this.setProduto.bind(this)} desabilitado={_.isEmpty(this.props.cliente)} default={this.props.produtoSelecionado}/>
                            </div>
                            <PedidoItem prod={this.props.produtoSelecionado} idCli={idCli} limpar={this.limparItem.bind(this)}/>
                        </div>  
                        <HistoricoVendaCliProd idCli={idCli} prod={this.props.produtoSelecionado}/>
                        <div style={{ padding: '10px 10px' }}>  
                            <ItensPedido/>
                        </div>
                    </ExpansionPanelDetails>
                </ExpansionPanel>    
            </div>
        )
    }    
}

const styles = ({
    expansionPanelSummary: {
        paddingLeft: '20px',
        background: '#ddd',

        minHeight: '35px',
        height: '35px',
    },
    expansionPanelDetails: {
        display: 'block',
        padding: 0
    }
});
     
function mapStateToProps(state) {    
    return {        
        produtoSelecionado: state.produtoSelecionado
    };
}

export default connect(mapStateToProps, { selecionarProduto })(withStyles(styles)(PedidoProd));