import React, { Component } from 'react';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import Fab from '@material-ui/core/Button';

class NavigationTop extends Component {

    constructor(props) {
        super(props)
        this.state = { exibe: false }
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = e => {
        this.setState({ exibe: window.scrollY > 20 })
    }

    handleTop = () =>  {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div onClick={this.handleTop} style={{display: this.state.exibe ? 'block' : 'none', position: 'fixed', bottom: 25, right: 25}}>  
                <Fab size="small" color="default">
                    <VerticalAlignTop></VerticalAlignTop>
                </Fab>
            </div>
        )
    }
}

export default NavigationTop