import axios from 'axios'
import APIUtils from '../utils/api_util'
import _ from 'lodash'
import { round, obterCoordenadas } from '../utils/rotinas'
import toastr from 'toastr'
import { mensagemErro } from '../utils/api_util'
export const OBTER_PARAMETROS = 'OBTER_PARAMETROS'
export const OBTER_PRODUTOS = 'OBTER_PRODUTOS'
export const OBTER_CLIENTES = 'OBTER_CLIENTES'
export const OBTER_EMPRESAS = 'OBTER_EMPRESAS'
export const OBTER_UNIDADES = 'OBTER_UNIDADES'
export const OBTER_CONDICOES = 'OBTER_CONDICOES'
export const OBTER_IPI_CF = 'OBTER_IPI_CF'
export const ADICIONAR_ITEM = 'ADICIONAR_ITEM'
export const REMOVER_ITEM = 'REMOVER_ITEM'
export const REMOVER_ITENS = 'REMOVER_ITENS'
export const SELECIONAR_PRODUTO = 'SELECIONAR_PRODUTO'
export const OBTER_TRANSPORTADORAS = 'OBTER_TRANSPORTADORAS'
export const SELECIONAR_CONDICAO = 'SELECIONAR_CONDICAO'
export const SELECIONAR_TRANSPORTADORA = 'SELECIONAR_TRANSPORTADORA'
export const ADD_PENDENTE = 'ADD_PENDENTE'
export const OBTER_PENDENTES = 'OBTER_PENDENTES'
export const REMOVER_PENDENTE = 'REMOVER_PENDENTE'
export const ADD_PROSPECT = 'ADD_PROSPECT'
export const OBTER_COMPROMISSOS = 'OBTER_COMPROMISSOS'
export const REMOVER_COMPROMISSO = 'REMOVER_COMPROMISSO'
export const SELECIONAR_CLIENTE = 'SELECIONAR_CLIENTE'

export function obterParametros(token) {
  // Primeira função irá colocar o token global no axios
  axios.interceptors.request.use((config) => {
    config.headers.common['Authorization'] = token
    return config
  })
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/parametros/representante`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_PARAMETROS, payload: response.data })
      dispatch(obterProdutos())
      dispatch(obterUnidades())
      dispatch(obterCondicoes())
      dispatch(obterIpiCfs())
      dispatch(obterTransportadoras())
      dispatch(obterPendentes())
      dispatch(obterClientes(response.data.idRep))
      dispatch(obterCompromissos(response.data.idRep))
    } catch (e) {
      dispatch({ type: OBTER_PARAMETROS, payload: e, error: true })
    }
  }
}

export function obterClientes(idRep) {
  return async (dispatch, getState) => {
    var url
    if (getState().parametros.superusuario) {
      // Superusuario terá acesso a todos os clientes
      url = `${APIUtils.API_URL}/api/v1/clientes/ativos/resumido`
    } else {
      url = `${APIUtils.API_URL}/api/v1/clientes/ativos/resumido/representante/${idRep}`
    }
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_CLIENTES, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_CLIENTES, payload: e, error: true })
    }
  }
}

export function obterProdutos() {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/produtos/disponiveis`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_PRODUTOS, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_PRODUTOS, payload: e, error: true })
    }
  }
}

export function obterUnidades() {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/tabelas/unidades`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_UNIDADES, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_UNIDADES, payload: e, error: true })
    }
  }
}

export function obterCondicoes() {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/tabelas/condpgto`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_CONDICOES, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_CONDICOES, payload: e, error: true })
    }
  }
}

export function obterIpiCfs() {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/tabelas/cfs/ipi`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_IPI_CF, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_IPI_CF, payload: e, error: true })
    }
  }
}

export function obterTransportadoras() {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/transportadoras`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_TRANSPORTADORAS, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_TRANSPORTADORAS, payload: e, error: true })
    }
  }
}

export function adicionarItem(prod, vlr, qtde, alqDesc, consFin) {
  return (dispatch, getState) => {
    const ipis = getState().ipiCfs
    const param = getState().parametros
    const cf = ipis.find((i) => i.id === prod.idCF)
    const vlrUnit = vlr
    const vlrUnitLiq = round(vlrUnit - (vlrUnit * alqDesc) / 100, 2)
    const vlrTotLiq = round(vlrUnitLiq * qtde, 2)
    const alqIPI = _.isEmpty(cf) ? 0 : cf.alqIPI
    const vlrIPI = round((vlrTotLiq * alqIPI) / 100, 2)
    const alqComis = param.alqComis
    const vlrComis = round((vlrTotLiq * alqComis) / 100, 2)
    const vlrTotIPI = round(vlrTotLiq + vlrIPI, 2)

    var item = {
      prod,
      idProd: prod.id,
      codAlt: prod.codAlt,
      descr: prod.descr,
      vlrUnit,
      vlrUnitLiq,
      vlrTotLiq,
      vlrIPI,
      qtde,
      alqDesc,
      alqComis,
      vlrComis,
      consFin,
      vlrTotIPI,
      alqIPI,
    }
    dispatch({ type: ADICIONAR_ITEM, payload: item })
  }
}

export function removerItem(item) {
  return {
    type: REMOVER_ITEM,
    payload: item,
  }
}

export function removerItens() {
  return {
    type: REMOVER_ITENS,
  }
}

export function selecionarProduto(prod) {
  return {
    type: SELECIONAR_PRODUTO,
    payload: prod,
  }
}

export function selecionarTransp(transp) {
  return {
    type: SELECIONAR_TRANSPORTADORA,
    payload: transp,
  }
}

export function selecionarCondicao(condicao) {
  return {
    type: SELECIONAR_CONDICAO,
    payload: condicao,
  }
}

export function salvarPedido(pedido, salvarPendente = true) {
  return async (dispatch) => {
    const coord = await obterCoordenadas()
    var url = ''
    if (_.isEmpty(coord)) {
      url = `${APIUtils.API_URL}/api/v1/pedidos/novo`
    } else {
      url = `${APIUtils.API_URL}/api/v1/pedidos/novo/${coord.lat}/${coord.lng}`
    }

    try {
      const config = {
        headers: { 'Content-type': 'application/json' },
        timeout: 8000,
      }
      await axios.post(url, JSON.stringify(pedido), config)
      dispatch(removerPendente(pedido))
      toastr.info('Pedido salvo com sucesso!')
    } catch (e) {
      if (salvarPendente) {
        dispatch(addPendente(pedido))
      }
      toastr.error('Erro salvando pedido: ' + mensagemErro(e))
    }
  }
}

export function addPendente(pedido) {
  return {
    type: ADD_PENDENTE,
    payload: pedido,
  }
}

export function obterPendentes() {
  return {
    type: OBTER_PENDENTES,
  }
}

export function removerPendente(pedido) {
  return {
    type: REMOVER_PENDENTE,
    payload: pedido,
  }
}

export function obterEmpresas() {
  return async (dispatch) => {
    var url = `${APIUtils.API_URL}/api/v1/empresas`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_EMPRESAS, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_EMPRESAS, payload: e, error: true })
    }
  }
}

export function addProspect(prospect, idEmp) {
  prospect['idEmp'] = idEmp
  prospect['tipo'] = 1 // Prospect
  return {
    type: ADD_PROSPECT,
    payload: prospect,
  }
}

export function obterCompromissos(idRep) {
  return async (dispatch) => {
    const url = `${APIUtils.API_URL}/api/v1/compromissos/${idRep}`
    try {
      const response = await axios.get(url)
      dispatch({ type: OBTER_COMPROMISSOS, payload: response.data })
    } catch (e) {
      dispatch({ type: OBTER_COMPROMISSOS, payload: e, error: true })
    }
  }
}

export function removerCompromisso(idComp) {
  return {
    type: REMOVER_COMPROMISSO,
    payload: idComp,
  }
}

export function selecionarCliente(cliente) {
  return {
    type: SELECIONAR_CLIENTE,
    payload: cliente,
  }
}
