import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';
import toastr from 'toastr';
import { obterParametros } from './actions/index';
import { connect } from 'react-redux';

class AuthOkta extends Component {

    constructor(props) {
        super(props);
        this.state = { authenticated: null };
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.checkAuthentication();
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            if (authenticated) {
                try {
                    const token = `Bearer ${await this.props.auth.getAccessToken()}`;
                    this.props.obterParametros(token);
                } catch (error) {
                    toastr.error("Erro obtendo credenciais");
                }
                this.setState({ authenticated: true });
            } else {
                this.setState({ authenticated: false });
            }
        }
    }

    componentDidUpdate() {
        this.checkAuthentication();
    }

    render() {
        if (this.state.authenticated === null) {
            return null;
        } else if (this.state.authenticated) {
            return this.props.children;
        } else {
            this.props.auth.login('/');
            return <div />;
        }

    }
}
export default (connect(null, { obterParametros })(withAuth(AuthOkta)));