import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FrameCondicoes from './frame_condicoes';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import _ from 'lodash';
import { round } from '../utils/rotinas';
import { removerItens } from '../actions/index';
import toastr from 'toastr';
import IconButton from '@material-ui/core/IconButton';
import DateRange from '@material-ui/icons/DateRange';
import moment from 'moment';
import 'moment/locale/pt-br';
import FrameTransportadora from './frame_transportadora';
import {
  selecionarCondicao,
  selecionarTransp,
  salvarPedido,
} from '../actions/index';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import APIUtils from '../utils/api_util';

const habilitaOperacao = APIUtils.REACT_APP_HC;

class PedidoTotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obs: '',
      dataPrev: moment(),
      totalProd: 0,
      totalIPI: 0,
      totalFrete: 0,
      total: 0,
      totalComis: 0,
      pendente: false,
      idOper: habilitaOperacao ? 1 : null,
    };
    this.finalizando = false;

    this.calcularTotais.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.itens, prevProps.itens) ||
      !_.isEqual(prevProps.cliente.cidade, this.props.cliente.cidade)
    ) {
      this.calcularTotais();
    }
  }

  setCondicao(condicao) {
    if (!_.isEqual(condicao, this.props.condicaoSelecionada)) {
      this.props.selecionarCondicao(condicao);
    }
  }

  setTransportadora(transportadora) {
    if (!_.isEqual(transportadora, this.props.transpSelecionada)) {
      this.props.selecionarTransp(transportadora);
    }
  }

  onObsChange(event) {
    this.setState({ obs: event.target.value });
  }

  onIdOperChange(event) {
    this.setState({ idOper: event.target.value });
  }

  onFreteChange(event) {
    const value = event.target.value.replace(',', '.');
    if (!isNaN(value)) {
      this.setState({
        totalFrete:
          parseFloat(value).toFixed(2) !== 'NaN'
            ? parseFloat(value).toFixed(2)
            : (0).toFixed(2),
      });
    }
  }

  onDataPrevChange(data) {
    this.setState({ dataPrev: data });
  }

  obterFreteIPI(vlrFrete, itens, item) {
    if (!item.alqIPI || item.alqIPI <= 0 || !vlrFrete || vlrFrete <= 0) {
      return 0;
    }

    const vlrTotProd = itens.reduce((prev, cur) => {
      return prev + cur.vlrTotLiq;
    }, 0);

    const { vlrTotLiq, alqIPI } = item;
    const div = vlrTotLiq / vlrTotProd;
    const vlrFreteItem = round(div * vlrFrete, 2);
    const vlrFreteIPI = round((vlrFreteItem * alqIPI) / 100, 2);

    return vlrFreteIPI;
  }

  calcularTotais() {
    var totalProd = 0;
    var totalIPI = 0;

    var totalFrete = 0;
    if (
      this.state.totalFrete !== 0 &&
      this.props.parametros.empresa !== 'Radax'
    ) {
      totalFrete = parseFloat(this.state.totalFrete);
    } else if (!_.isEmpty(this.props.itens)) {
      // Valor de frete de R$ 16,00 para clientes de Bento Gonçalves (Somente para a Radax)
      totalFrete =
        this.props.cliente.cidade === 'BENTO GONCALVES' &&
        this.props.parametros.empresa === 'Radax'
          ? 16.0
          : this.props.parametros.vlrFrete;
    }

    var total = 0;
    var totalComis = 0;
    this.props.itens.forEach((i) => {
      totalProd = totalProd + i.vlrTotLiq;
      totalIPI =
        totalIPI +
        i.vlrIPI +
        this.obterFreteIPI(totalFrete, this.props.itens, i);
      totalComis = totalComis + i.vlrComis;
    });
    // Arredondamento
    totalProd = round(totalProd, 2);
    totalIPI = round(totalIPI, 2);
    totalComis = round(totalComis, 2);
    total = totalProd + totalIPI + totalFrete;
    total = round(total, 2);
    this.setState({
      totalProd,
      totalIPI,
      totalFrete,
      total,
      totalComis,
    });
  }

  async finalizar() {
    if (this.finalizando) {
      toastr.warning('Pedido já está sendo finalizado!');
      return;
    }
    this.finalizando = true;
    if (_.isEmpty(this.props.cliente)) {
      toastr.error('É preciso informar um cliente');
      this.finalizando = false;
      return;
    }
    if (_.isEmpty(this.props.itens)) {
      toastr.error(
        'Para salvar o pedido é preciso informar pelo menos um item'
      );
      this.finalizando = false;
      return;
    }
    if (_.isEmpty(this.props.parametros) || this.props.parametros.idRep <= 0) {
      toastr.error('Erro com as informações de representante');
      this.finalizando = false;
      return;
    }
    if (_.isEmpty(this.props.condicaoSelecionada)) {
      toastr.error('É preciso informar uma condição de pagamento');
      this.finalizando = false;
      return;
    }

    try {
      const itens = [];
      this.props.itens.forEach((i) => {
        const item = {
          idProd: i.idProd,
          qtde: i.qtde,
          vlrUnit: i.vlrUnit,
          alqDesc: i.alqDesc,
          consumidorFinal: i.consFin === true ? 1 : 0,
        };
        itens.push(item);
      });
      const dt = this.state.dataPrev.format('DD-MM-YYYY');
      const pedido = {
        // ID para verificar duplicados na lista de pendentes
        guid: new Date().getTime(),
        idRep: this.props.parametros.idRep,
        idCli: this.props.cliente.id,
        idCond: this.props.condicaoSelecionada.id,
        vlrFrete: this.state.totalFrete,
        obs: this.state.obs,
        dataPrev: dt,
        idTrp: this.props.transpSelecionada.id,
        idOper: this.state.idOper,
        itens,
      };

      this.props.salvarPedido(pedido);
      this.limpar();
    } catch (e) {
      toastr.error('Erro tentando finalização: ' + e);
    } finally {
      this.finalizando = false;
    }
  }

  limpar() {
    this.props.removerItens();
    this.props.setCliente({});
    this.props.selecionarTransp({});
    this.props.selecionarCondicao({});
    this.setState({
      obs: '',
      condicao: {},
      transportadora: {},
      dataPrev: moment(),
      totalFrete: 0,
      idOper: habilitaOperacao ? 1 : null,
    });
  }

  render() {
    const { classes } = this.props;

    const idsOperOptions = [
      { id: 1, descr: 'Venda' },
      { id: 11, descr: 'Remessa em Comodato Kimberly' },
      { id: 46, descr: 'Remessa para troca de mercadorias Kimberly' },
    ];

    return (
      <div>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.expansionPanelSummary}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography>Totalizadores</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionPanelDetails}>
            <div>
              <div style={{ padding: 10 }}>
                <Typography color="inherit" variant="caption">
                  {' '}
                  Valor dos produtos: R$ {this.state.totalProd.toFixed(2)}{' '}
                </Typography>
                <Typography color="inherit" variant="caption">
                  {' '}
                  Valor IPI: R$ {this.state.totalIPI.toFixed(2)}{' '}
                </Typography>
                <Typography paragraph={true} color="inherit" variant="caption">
                  {' '}
                  Valor frete:{' '}
                  <TextField
                    value={parseFloat(this.state.totalFrete).toFixed(2)}
                    onChange={this.onFreteChange.bind(this)}
                    onBlur={this.calcularTotais.bind(this)}
                    style={{ scale: 0.5 }}
                    className={classes.freteInput}
                    InputProps={{
                      readOnly: this.props.parametros.empresa === 'Radax',
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                </Typography>

                <Typography color="secondary" variant="caption">
                  {' '}
                  Valor total pedido: R$ {this.state.total.toFixed(2)}{' '}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {' '}
                  Valor comissão: R$ {this.state.totalComis.toFixed(2)}{' '}
                </Typography>
              </div>
              <div style={{ padding: '10px 10px 10px 10px' }}>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  libInstance={moment}
                  locale="pt-br"
                >
                  <DatePicker
                    autoOk
                    variant="inline"
                    label="Data prevista"
                    value={this.state.dataPrev}
                    onChange={(date) => this.onDataPrevChange(date)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="Selecione uma data"
                          color="inherit"
                        >
                          {' '}
                          <DateRange />{' '}
                        </IconButton>
                      ),
                    }}
                    format="DD/MM/YYYY"
                  ></DatePicker>
                </MuiPickersUtilsProvider>
              </div>
              <div style={{ padding: 10 }}>
                <FrameCondicoes
                  selecionarCondicao={this.setCondicao.bind(this)}
                  default={this.props.condicaoSelecionada}
                />
              </div>
              <div style={{ padding: 10 }}>
                <FrameTransportadora
                  selecionarTransportadora={this.setTransportadora.bind(this)}
                  default={this.props.transpSelecionada}
                />
              </div>
              {habilitaOperacao && (
                <div style={{ padding: 10 }}>
                  <FormControl variant="outlined" className={classes.select}>
                    <InputLabel id="idOper">Tipo Operação</InputLabel>
                    <Select
                      labelId="idOper"
                      label="Tipo Operação"
                      value={this.state.idOper}
                      onChange={this.onIdOperChange.bind(this)}
                    >
                      {idsOperOptions.map((option) => {
                        return (
                          <MenuItem key={option.id} value={option.id}>
                            {option.descr}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div style={{ padding: 10 }}>
                <div style={{ border: '1px solid #ddd', padding: 10 }}>
                  <TextField
                    value={this.state.obs}
                    onChange={this.onObsChange.bind(this)}
                    inputProps={{ maxLength: '255' }}
                    multiline={true}
                    placeholder="Observações"
                    fullWidth={true}
                  ></TextField>
                </div>
              </div>
              <div style={{ padding: 10, textAlign: 'right' }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.finalizar.bind(this)}
                >
                  Finalizar pedido
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

const styles = {
  expansionPanelSummary: {
    paddingLeft: '20px',
    background: '#ddd',

    minHeight: '35px',
    height: '35px',
  },
  expansionPanelDetails: {
    display: 'block',
    padding: 0,
  },
  freteInput: {
    transform: 'scale(0.8)',
    marginLeft: '-12px',
    marginTop: '-6px',
    maxWidth: '120px',
  },
  select: {
    width: '100%',
  },
};

function mapStateToProps(state) {
  return {
    itens: state.itensPedido,
    parametros: state.parametros,
    condicaoSelecionada: state.condicaoSelecionada,
    transpSelecionada: state.transpSelecionada,
    flagPendente: state.flagPendente,
    pendentes: state.pedidosPendentes,
  };
}

export default connect(mapStateToProps, {
  removerItens,
  selecionarCondicao,
  selecionarTransp,
  salvarPedido,
})(withStyles(styles)(PedidoTotal));
