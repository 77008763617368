import * as toastr from 'toastr';
import { OBTER_PRODUTOS } from '../actions/index';

export default function (state = {}, action) {
    switch (action.type) {
        case OBTER_PRODUTOS:                
            if(action.error){
                toastr.error("Erro obtendo produtos.");
                return state;                
            }else{           
                return action.payload;
            }

        default:
            return state;
    }   
}
