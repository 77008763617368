export default class API{
    static API_URL = `${process.env.REACT_APP_URL_API}`;
    static REACT_APP_HC = process.env.REACT_APP_HC === 'yes' ? true : false
}

export function mensagemErro(error){
    var msg = '';    
    if (error.response) {
        // Requisição foi feita e o servidor responder com erro na faixa de 2xx
        msg = error.response.status + ': ' + error.response.data.error + ' - ' + error.response.data.message;
        console.log(error.response);
    } else if (error.request) {
        // Requisição foi feita mas não foi obtido resposta
        msg = 'Não foi possível conectar-se ao servidor. Verifique sua conexão';
        console.log(error.request);        
    } else {
        // Algo na configuração da requisição causou o erro
        msg = "Erro desconhecido: " + error.message;
        console.log(error.message);
    }    

    return msg;
}