import React from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/icons/SelectAll';

const styles = theme => ({

    root: {
        width: '100%',
        overflowX: 'auto'
    },

    thead: {
        background: '#ccc'
    },

    theadTr: {
        height: '30px'
    },

    tbodyTr: {
        height: '30px'
    },

    tableCell: {
        padding: '4px 4px 4px 4px',
        '&:last-of-type': { paddingRight: 0 },
        '& button' : { padding: 7 },
        '& button > span > svg': { fontSize: 16 }
    }            
})

const CustomTable = (props) => {

    const { classes } = props

    function renderHeader() {        
        return (
            props.header.map((head) => {
                return <TableCell key={_.uniqueId()} className={classes.tableCell} align={ _.isEqual(head.type, 'numeric') ? 'right' : 'left'}>{ head.label }</TableCell>
            })
        )
    }

    function renderBody() {
        if(!props.dados){
            return null;
        }
        return (
            <TableBody>
                {props.dados.map((dado) => {
                    return (
                        <TableRow className={classes.tbodyTr} key={_.uniqueId()}>
                            { 
                                props.header.map((head) => {
                                    if (head.type !== "excluir" && head.type !== "selecionar") {
                                        const numeric =  _.isEqual(head.type, 'numeric');
                                        return (
                                            <TableCell
                                                key={_.uniqueId()} 
                                                className={classes.tableCell} 
                                                align={numeric ? 'right' : 'left'}>
                                                    { numeric ? Number(dado[head.field]).toFixed(2) : dado[head.field]}
                                            </TableCell>
                                        )
                                    } else {
                                        if (head.type !== "selecionar") {
                                            return ( 
                                                <TableCell
                                                    key={_.uniqueId()}
                                                    className={classes.tableCell}>
                                                    <IconButton onClick={ () => props.excluir(dado) }> 
                                                        <DeleteIcon /> 
                                                    </IconButton>
                                                </TableCell>
                                            )
                                        } else {
                                            return ( 
                                                <TableCell
                                                    key={_.uniqueId()}
                                                    className={classes.tableCell}>
                                                    <IconButton onClick={ () => props.selecionar(dado.idProd)}> 
                                                        <Select />
                                                    </IconButton>
                                                </TableCell>
                                            )    
                                        }    
                                    }
                                })
                            }
                        </TableRow>
                    )
                })}
            </TableBody>
        )    
    }

    return(
        <Paper className={classes.root}>
            <Table>
                <TableHead className={classes.thead}>
                    <TableRow className={classes.theadTr}>
                        { renderHeader() }
                    </TableRow>    
                </TableHead>   
                { renderBody() }
            </Table>        
        </Paper>
    )
}

export default withStyles(styles)(CustomTable)