import React, { Component } from 'react';
import { connect } from 'react-redux';
import FramePesquisa from './frame_pesquisa';

class FrameCondicoes extends Component {

    render() {
        return(
            <FramePesquisa 
                action={this.props.selecionarCondicao}
                dados={this.props.condicoes}
                desabilitado={this.props.desabilitado}
                tamanho={1}
                campos={['descr']}
                placeholder="Selecione uma condição de pagamento"
                {...this.props}
            />
        );
    }
}

function mapStateToProps(state) {    
    return {
        condicoes: state.condicoes,
    };
}

export default connect(mapStateToProps, null)(FrameCondicoes);


