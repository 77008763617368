import React from 'react'
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import moment from 'moment';

const TableContasAberto = (props) => {

    const { classes } = props

    function renderHeader() {        
        return (
            <TableHead className={classes.thead}>
                <TableRow className={classes.theadTr}>
                    <TableCell className={classes.tableCell}>Data</TableCell>
                    <TableCell className={classes.tableCell}>Título</TableCell>
                    <TableCell className={classes.tableCell}>Parcela</TableCell>
                    <TableCell className={classes.tableCell}>Vcto</TableCell>
                    <TableCell className={classes.tableCell} align='right'>Saldo</TableCell>            
                </TableRow>
            </TableHead>    
        )
    }

    function renderBody() {
        if(!props.contas){
            return null;
        }

        return (
            <TableBody>
                {props.contas.map((conta) => {

                    const vencida = moment().isAfter(moment(conta.dtVenc, 'DD-MM-YY'))
                    const estilo = { color: vencida ? 'red' : '' }

                    return (
                        <TableRow className={classes.tbodyTr} key={_.uniqueId()} >
                            <TableCell style={ estilo } 
                                key={_.uniqueId()}
                                className={classes.tableCell}>
                                {conta.dtEmis}
                            </TableCell>
                            <TableCell style={ estilo } 
                                key={_.uniqueId()}
                                className={classes.tableCell}>
                                {conta.numDoc}
                            </TableCell>
                            <TableCell style={ estilo } 
                                key={_.uniqueId()}
                                className={classes.tableCell}>
                                {conta.numParc}
                            </TableCell>
                            <TableCell style={ estilo } 
                                key={_.uniqueId()}
                                className={classes.tableCell}>
                                {conta.dtVenc}
                            </TableCell>
                            <TableCell style={ estilo } 
                                key={_.uniqueId()}
                                className={classes.tableCell}
                                align='right'>
                                { Number(conta.vlrSaldo).toFixed(2) }
                            </TableCell>                            
                        </TableRow>
                    )
                })}
            </TableBody>
        )    
    }

    return(
        <Paper className={classes.root}>
            <Table>
                { renderHeader() }
                { renderBody() }
            </Table>        
        </Paper>
    )
}

const styles = ({

    root: {
        width: '100%',
        overflowX: 'auto'
    },

    thead: {
        background: '#ccc'
    },

    theadTr: {
        height: '30px'
    },

    tbodyTr: {
        height: '30px'
    },

    tableCell: {
        padding: '4px 4px 4px 4px',
        '&:last-of-type': { paddingRight: '4px' },
        '& button': { width: 20, height: 20 },
        '& button > span > svg': { fontSize: 18 }
    }            
})

export default withStyles(styles)(TableContasAberto)