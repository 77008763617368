import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import _ from 'lodash';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import Chip from './custom_chip';
import CustomTable from './custom_table';

class PedidosEmAberto extends Component {

    constructor(props) {
        super(props);
        this.state = { pedidos: null, erro: false };
    }

    componentDidMount() {
        this.mounted = true;
        this.obterPedidos(this.props.idCli);
    }

    componentDidUpdate(prevProps) {      
        if(this.props.idCli !== prevProps.idCli){
            this.obterPedidos(this.props.idCli);
        }   
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    async obterPedidos(idCli){        
        if(idCli > 0){
            const url = `${APIUtils.API_URL}/api/v1/pedidos/pendentes/${idCli}`;
            try{
                const response = await axios.get(url);
                if(this.mounted){
                    this.setState({ pedidos: response.data, erro: false});
                }
                
            } catch(e){
                if(this.mounted){
                    this.setState({ pedidos: null, erro: true});
                }
            }
        }      
    }   

    render() {
        if(!_.isEmpty(this.state.pedidos)){
            return(
                <div style={{ padding: '10px 10px' }}>
                    <FormLabel style={{marginBottom: '10px'}} component="legend">Pedidos em aberto</FormLabel>                                                    
                    <CustomTable header={headerPedidos} dados={this.state.pedidos}/>
                </div> 
            )             
        }else{
            if(this.state.erro){
                return <RefreshAction descr='Pedidos em aberto' action={this.obterPedidos.bind(this, this.props.idCli)}/>            
            }else{
                if(this.props.idCli > 0){
                    if(this.state.pedidos === null){
                        return <div>Carregando...</div>    
                    }else{                        
                        return <div><Chip label="Nenhum pedido em aberto"/></div>                    
                    }
                }else{
                    return <div/>
                }
            }
        }        
    }
}

const headerPedidos = 
    [{field: 'id', label: 'Pedido', type: ''}, 
    {field: 'status', label: 'Status', type: ''}, 
    {field: 'vlrTotal', label: 'Valor total', type: 'numeric'},
    {field: 'data', label: 'Data', type: ''},
    {field: 'dataPrev', label: 'Previsão', type: ''},
]


export default PedidosEmAberto;