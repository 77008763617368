import React from 'react'
import Chip from '@material-ui/core//Chip';

const FrameItem = (props) => {

    return (
        <div style={{ margin: '5px', textAlign: 'center' }}>
            <Chip label= {props.label} style={{ fontWeight: 'bold', color: props.color }} />    
        </div>
    )    
}

export default FrameItem