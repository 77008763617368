import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import DateRange from '@material-ui/icons/DateRange';
import Badge from '@material-ui/core/Badge';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Notifications from '@material-ui/icons/Notifications';
import Dialogo from './dialogo'
import { connect } from 'react-redux'
import { salvarPedido } from '../actions/index'
import _ from 'lodash';
import ListaCompromissos from './lista_compromissos';
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

class MenuPrincipal extends Component {

    constructor(props){
        super(props);
        moment.locale('pt-BR');
        this.data = moment()
        this.state = {
            anchor: null,
            openDialog: false,
            exibeCompromissos: false,            
            openPicker: false,
        };
        this.toggleCompromissos = this.toggleCompromissos.bind(this);
    }

	toggleCompromissos() {
		this.setState({ exibeCompromissos: !this.state.exibeCompromissos });
	}    


    handleMenu(e) {
        this.setState({ anchor: e.currentTarget });
        this.props.action()
    };
    
    handleClose() {
        this.setState({ anchor: null });
    };


    atualizar() {
        this.props.history.push("/");
    }

    handleOpen(value) {
        this.setState({ openDialog: value })
    }

    enviarPendentes() {
        const pendentes = _.cloneDeep(this.props.pendentes);
        pendentes.forEach(pedido => {
            this.props.salvarPedido(pedido, false);
        });
    }

	renderCompromissos() {
		if (this.state.exibeCompromissos && !_.isEmpty(this.props.compromissos) && this.props.compromissos.length > 0) {
			return <ListaCompromissos />
		} else {
			return null;
		}
    }

    
    render() {
        const { classes } = this.props
        const qtdeComp = !_.isEmpty(this.props.compromissos) ? this.props.compromissos.length : 0;
        const qtdePendentes = !_.isEmpty(this.props.pendentes) ? this.props.pendentes.length : 0;
        return (
            <React.Fragment>                                
                <AppBar color="primary">
                    <Toolbar className={ classes.flex } style={{ minHeight: 0 }}>
                        <div>
                            <IconButton
                                onClick={ this.handleMenu.bind(this) }
                                color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <Typography variant="subtitle1" color="inherit" noWrap>
                            Novo Pedido
                        </Typography>
                        
                        <div className={ classes.flex }>
                            <IconButton className={classes.margin} color="secondary" style={{ color: this.props.compromissos.length > 0 ? 'red' : '#fff' }} onClick={ this.toggleCompromissos }>
                                <Badge badgeContent={qtdeComp}>
                                    <Notifications />
                                </Badge>
                            </IconButton>
                            <IconButton className={classes.margin} color="secondary" style={{ color: this.props.pendentes.length > 0 ? 'yellow' : '#fff' }} onClick={ this.handleOpen.bind(this, true) }>
                                <Badge badgeContent={qtdePendentes}>
                                    <CloudUpload />
                                </Badge>
                            </IconButton>
                            <div>
                                <IconButton 
                                    onClick={() => this.setState({openPicker: true})} 
                                    style={{ color: '#fff' }}
                                >
                                    <DateRange />
                                </IconButton>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <div style={{ display: 'none' }} className="picker">
                                        <DatePicker
                                            open={this.state.openPicker}
                                            variant='inline'
                                            value={moment()}
                                            onClose={() => this.setState({openPicker: false})}
                                            onChange={() => {}}
                                        >
                                        </DatePicker>
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                    </Toolbar>            
                </AppBar>                
                <Dialogo open={((this.state.openDialog) && (this.props.pendentes.length > 0))} acaoOpen={this.handleOpen.bind(this)}
                    titulo="Confirmação de envio" 
                    conteudo="Confirma o envio dos pedidos pendentes?"
                    acaoConfirma={this.enviarPendentes.bind(this)}>
                </Dialogo>  
                {this.renderCompromissos()}                  
            </React.Fragment>
        )
    }
}

const styles = ({
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    }
});

function mapStateToProps(state) {
    return {
        pendentes: state.pedidosPendentes,
        compromissos: state.compromissos
    }
}

export default connect(mapStateToProps, { salvarPedido }) (withStyles(styles)(MenuPrincipal))