export function round(value, decimals) {
    return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}


export async function obterCoordenadas() {
    const gps_options = { enableHighAccuracy: true, maximumAge: 120000, timeout: 15000 };
    if (navigator.geolocation) {
        try {
            // Tentará buscar a posição real (ultimo 2 minutos - com base no tracking) com 15 segundos de timeout            
            const position = await getCurrentPosition(gps_options);
            const coords = { lat: position.coords.latitude, lng: position.coords.longitude };                       
            return coords;
          } catch (error) {              
              // Caso falha, tentará posição em cache dos ultimos 5 minutos              
              try {
                const gps_options2 = { enableHighAccuracy: true, maximumAge:300000 , timeout: 15000 };              
                const position2 = await getCurrentPosition(gps_options2);     
                const coords2 = { lat: position2.coords.latitude, lng: position2.coords.longitude };                                       
                return coords2;
              } catch (error) {
                  return {};
              }              
          }        
    }else{
        return {};
    }
}

const getCurrentPosition = (options = {}) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
};

