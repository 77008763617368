import React, { Component } from 'react';
import ButtonUpDown from './custom_button';
import CustomTable from './custom_table';
import FormLabel from '@material-ui/core/FormLabel';
import _ from 'lodash';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import Chip from './custom_chip';

const LIMIT = 10;

class HistoricoVendaCliProd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            exibeHistoricoProduto: false,
            historico: null,
            erro: false
        }    
    }

    componentDidMount() {      
        this.mounted = true;
        if(this.state.exibeHistoricoProduto) {
            this.obterHistorico(this.props.idCli, this.props.prod);
        }
    }    

    componentDidUpdate(prevProps) {
        if(this.state.exibeHistoricoProduto){
            if(this.props.idCli !== prevProps.idCli || !_.isEqual(this.props.prod, prevProps.prod)){
                this.obterHistorico(this.props.idCli, this.props.prod);
            }
        }
    }    

    componentWillUnmount(){
        this.mounted = false;
    }    

    async obterHistorico(idCli, prod){
        if(_.isEmpty(prod)){
            if(this.mounted){
                this.setState({ historico: '', erro: false });
            }
            return;
        }
        const idProd = prod.id;
        if(idCli > 0 && idProd > 0){
            const url = `${APIUtils.API_URL}/api/v1/historico/vendas/${idCli}/${idProd}?size=${LIMIT}`;
            try{
                const response = await axios.get(url);
                if(this.mounted){
                    this.setState({ historico: response.data, erro: false});
                }
            } catch(e){
                if(this.mounted){
                    this.setState({ historico: null, erro: true});
                }
            }
        }else{
            if(this.mounted){
                this.setState({ historico: null, erro: false })
            }
        }        
    }    

    onHistoricoProdutoClick() {
        const exibir = !this.state.exibeHistoricoProduto;
        if(exibir){
            this.obterHistorico(this.props.idCli, this.props.prod);
        }
        this.setState({ exibeHistoricoProduto: exibir })
    }

    renderHistorico(){
        if(!_.isEmpty(this.state.historico)){
            return (
                <div style={{ padding: '10px 10px' }}>
                    <FormLabel style={{marginBottom: '10px'}}  component="legend">Histórico de Venda Cliente/Produto (Últimas {LIMIT})</FormLabel>                                                    
                    <CustomTable header={headerVendasProduto} dados={this.state.historico}/>
                </div>    
            );
        }else{
            if(this.state.erro){
                return <RefreshAction descr='Histórico de venda Cliente/Produto' action={this.obterHistorico.bind(this, this.props.idCli, this.props.prod)}/>
            }else{
                if(this.props.idCli > 0) {
                    if(!_.isEmpty(this.props.prod) && this.state.historico === null){
                        return <div>Carregando...</div>
                    }else{
                        return <div><Chip label="Cliente não teve nenhuma compra desse produto"/></div>
                    }                    
                }else{
                    return <div/>
                }
            }
        }
    }

    render() {
        return (
            <div style={{ padding: '10px 10px' }}>
                <div style={{ marginBottom: '5px' }}>
                    <ButtonUpDown
                        label="histórico"
                        exibe={this.state.exibeHistoricoProduto}
                        onClick={this.onHistoricoProdutoClick.bind(this)}>
                    </ ButtonUpDown>    
                </div>    

                {this.state.exibeHistoricoProduto && this.renderHistorico()}
            </div>    
        )
    }
}

const headerVendasProduto = 
    [
        {field: 'data', label: 'Data', type: ''}, 
        {field: 'tipo', label: 'Tipo', type: ''},
        {field: 'qtde', label: 'Qtde', type: 'numeric'}, 
        {field: 'vlrLiq', label: 'Valor liq.', type: 'numeric'},
    ]

export default HistoricoVendaCliProd;