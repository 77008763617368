import _ from 'lodash';
import { OBTER_PENDENTES, ADD_PENDENTE, REMOVER_PENDENTE } from '../actions/index';

export default function(state = [], action) {    
    switch (action.type) {
        case ADD_PENDENTE: {
            const pendentes = state.concat([action.payload]);
            localStorage.setItem('pendentes', JSON.stringify(pendentes));
            return pendentes;                            
        }
        case OBTER_PENDENTES: 
            const lsPendentes = localStorage.getItem('pendentes');
            if(_.isEmpty(lsPendentes)){
                return [];
            }else{
                return JSON.parse(lsPendentes);
            }                  
        case REMOVER_PENDENTE: 
            const pendentes = state.filter(pendente => !_.isEqual(pendente, action.payload));
            localStorage.setItem('pendentes', JSON.stringify(pendentes));
            return pendentes;
        default: 
            return state;
    }
}