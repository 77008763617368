import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import CustomTable from './custom_table'
import _ from 'lodash';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import RefreshAction from './refresh_action';
import Chip from './custom_chip';
import { connect } from 'react-redux';
import { selecionarProduto } from  '../actions/index';

const LIMIT = 40;

class HistoricoVendaCli extends Component {

    constructor(props) {
        super(props);
        this.state = { historico: null, erro: false };
    }

    componentDidMount(){
        this.mounted = true;
        this.obterHistorico(this.props.idCli);
    }

    componentDidUpdate(prevProps) {
        if (this.props.idCli !== prevProps.idCli) {
            this.obterHistorico(this.props.idCli);
        }
    }

    componentWillUnmount(){
        this.mounted = false;
    }    

    async obterHistorico(idCli){
        if(idCli > 0){
            const url = `${APIUtils.API_URL}/api/v1/historico/vendas/${idCli}?size=${LIMIT}`;
            try{
                const response = await axios.get(url);
                if(this.mounted){
                    this.setState({ historico: response.data, erro: false});
                }
            } catch(e){
                if(this.mounted) {
                    this.setState({ historico: null, erro: true});
                }
            }
        }        
    }  
    
    selecionarProduto(id) {        
        var prod = this.props.produtos.find(p => p.id === id);
        this.props.selecionarProduto(prod);
    }

    render() {
        if(!_.isEmpty(this.state.historico)){
            return (
                <div style={{ padding: '10px 10px' }}>
                    <FormLabel style={{marginBottom: '10px'}}  component="legend">Histórico de Vendas (Ultimos {LIMIT} itens)</FormLabel>                                                    
                    <CustomTable selecionar={this.selecionarProduto.bind(this)} header={headerVendas} dados={this.state.historico}/>
                </div>    
            );
        }else{
            if(this.state.erro){
                return <RefreshAction descr='Histórico de Vendas' action={this.obterHistorico.bind(this, this.props.idCli)}/>                    
            }else{
                if(this.props.idCli > 0){
                    if(this.state.historico === null) {
                        return <div>Carregando...</div>
                    }else{
                        return <div><Chip label="Cliente não possuí nenhum histórico"/></div>
                    }
                }else{
                    return <div/>
                }
            }
        }     
    }
}

const headerVendas = 
    [{field: 'data', label: 'Data', type: ''}, 
     {field: 'numero', label: 'Num.', type: ''}, 
     {field: 'tipo', label: 'Tipo', type: ''},
     {field: 'produto', label: 'Produto', type: ''},
     {field: 'qtde', label: 'Qtde', type: 'numeric'},
     {field: 'vlrLiq', label: 'Valor', type: 'numeric'},
     {field: 'acao', label: 'ação', type: 'selecionar'}]

function mapStateToProps(state)  {
    return {
        produtos: state.produtos
    }    
}     

export default connect(mapStateToProps, { selecionarProduto })(HistoricoVendaCli);