import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Dialogo extends Component {

    handleAcao() {
        this.props.acaoConfirma()
        this.props.acaoOpen(false)
    }
    
    render() {
        return (
            <div>
                <Dialog open={this.props.open} onClose={() => this.props.acaoOpen(false)}>
                    <DialogTitle>{this.props.titulo}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.props.conteudo}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.acaoOpen(false)} color="primary" autoFocus>
                            Cancela
                        </Button>
                        <Button onClick={this.handleAcao.bind(this)} color="primary">
                            Confirma
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>    
        )
    }
}

export default Dialogo