import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuPrincipal from './menu_principal';
import Pedido from './pedido';
import NavigationTop from './navigation_top';
import NavBar from './nav_bar';
import Tracking from './tracking';
import { connect } from 'react-redux';
import _ from 'lodash';

class Dashboard extends Component {
    
    state = {
        openNav: false
    }

    preventBackNavigation() {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            window.history.go(1);
        };        
    }

    toggleDrawer() {
        this.setState({ openNav: !this.state.openNav });
    }

    componentDidMount() {
        this.preventBackNavigation();   
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>                
                {!_.isEmpty(this.props.parametros) && <Tracking idRep={this.props.parametros.idRep}/>}
                <MenuPrincipal action={this.toggleDrawer.bind(this)}/>                   
                <main className={classes.content}>                    
                    <Pedido />  
                    <NavigationTop />
                </main>
                
                <NavBar history={this.props.history} open={this.state.openNav} action={this.toggleDrawer.bind(this)} />
            </div>        
        )   
    }
}

const styles = ({
    root: {
      height: '100%'
    },
    content: {
      padding: '45px 0'
    }
});

function mapStateToProps(state) {    
    return {
        parametros: state.parametros,
    };
}

export default connect(mapStateToProps, null) (withStyles(styles)(Dashboard));