import React, { Component } from 'react';
import Routes from './routes'; 
import { Security } from '@okta/okta-react';
import { BrowserRouter } from 'react-router-dom';

const config = {
  issuer: 'https://dev-601977.oktapreview.com/oauth2/default',
  redirectUri: window.location.origin + '/implicit/callback',
  clientId: process.env.REACT_APP_CLIENT_ID
}

const gps_options = {
  enableHighAccuracy: true,
  maximumAge: 300000,
  timeout: 60000
}


class App extends Component {

  constructor(props){
    super(props);
    this.state = { gpsHabilitado: false, gpsMsg: 'obtendo informações...' };
    this.gpsSucesso = this.gpsSucesso.bind(this);
    this.gpsErro = this.gpsErro.bind(this);
  }

  componentDidMount(){
    if(process.env.REACT_APP_HAB_GPS === '1') {
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(this.gpsSucesso, this.gpsErro, gps_options);
      }else{
        this.setState({ gpsHabilitado: false });
      }
    }else{
      this.setState({ gpsHabilitado: true });
    }
  }

  gpsSucesso(position){    
    this.setState({ gpsHabilitado: true });
  }

  gpsErro(error){
    var erro = '';
    switch(error.code) {
      case error.PERMISSION_DENIED:
          erro = "permissão negada";
          break;
      case error.POSITION_UNAVAILABLE:
          erro = "informação do local não disponível";
          break;
      case error.TIMEOUT:
          erro = "timeout";
          break;
      case error.UNKNOWN_ERROR:
          erro = "erro desconhecido";
          break;
      default:
        erro = "código de erro não tratado: " + error.code;
        break;
    }
    this.setState({ gpsHabilitado: false, gpsMsg: erro});
  }

  render() {  
    if(!this.state.gpsHabilitado){
      return (
        <div>
          <h2> Status: {this.state.gpsMsg} </h2>
          <button onClick={() => window.location.reload()}>Tentar novamente</button>
        </div>          
      );
    }else{
      return (              
        <div>
          <BrowserRouter>
              <Security issuer={config.issuer} client_id={config.clientId} redirect_uri={config.redirectUri}>
                  <Routes/>
              </Security>
          </BrowserRouter>
        </div>        
      );    
    }
  }
}

export default App;